@if $use-fadeOutLeftBig == true {

	@-webkit-keyframes fadeOutLeftBig {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateX(-$base-distance-big * 2);
		}
	}

	@keyframes fadeOutLeftBig {
		0% {
			opacity: 1;
			transform: translateX(0);
		}

		100% {
			opacity: 0;
			transform: translateX(-$base-distance-big * 2);
		}
	}

	.fadeOutLeftBig {
		@include animate-prefixer(animation-name, fadeOutLeftBig);
	}

}
