.resources {
	&__list {
		list-style-type: none;
	}
	&__list-item {
		padding: 0;
		margin: 0;
		&:not(:last-of-type) {
			margin-bottom: map-get($spacers, 4);
		}
	}
	&__link {
		display: inline-block;
		padding-left: calc(1.25rem + #{$spacer});
		position: relative;
		color: $link-color;
		font-size: $font-size-sm;
		transition: $transition-base;
		&::before {
			content: "";
			width: 1.25rem;
			height: 1.25rem;
			background-size: 1.25rem 1.25rem;
			background-position: center;
			background-repeat: no-repeat;
			background-image: str-replace(
				url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 22.41 23.23' fill='#{$link-color}'> <g transform='translate(-9)'> <path d='M29.371,145H11.042A2.044,2.044,0,0,0,9,147.042v8.167a2.044,2.044,0,0,0,2.042,2.042h18.33a2.044,2.044,0,0,0,2.042-2.042v-8.167A2.044,2.044,0,0,0,29.371,145Zm-14.984,7.184-.75,0v1.57a.681.681,0,0,1-1.361,0l-.01-5.17a.68.68,0,0,1,.681-.683h1.44a2.14,2.14,0,1,1,0,4.28Zm5.842,2.089c-.405.007-1.419.011-1.462.011h0a.681.681,0,0,1-.681-.678c0-.018-.01-5.02-.01-5.02a.681.681,0,0,1,.681-.682h1.421c1.619,0,2.707,1.282,2.707,3.19C22.884,152.909,21.767,154.246,20.23,154.273Zm6.974-3.9a.681.681,0,1,1,0,1.361H25.969v1.935a.681.681,0,1,1-1.361,0v-5.134a.681.681,0,0,1,.681-.681h2.081a.681.681,0,1,1,0,1.361h-1.4v1.157Z' transform='translate(0 -138.421)' /> <path d='M239.767,239h-.74c0,.614,0,3.063.007,3.654.283,0,.586,0,.763-.007.909-.016,1.317-.925,1.317-1.818C241.113,240.4,241.016,239,239.767,239Z' transform='translate(-219.591 -228.156)' /> <path d='M111.8,239h-.758c0,.3,0,.625,0,.779,0,.18,0,.492,0,.782l.753,0a.779.779,0,1,0,0-1.558Z' transform='translate(-97.415 -228.156)' /> <path d='M82.125,5.218A3.4,3.4,0,0,0,81.4,4.043L78.571,1.062A3.417,3.417,0,0,0,76.1,0h-9.06A2.044,2.044,0,0,0,65,2.042V5.218Z' transform='translate(-53.459)' /> <path d='M65,445v1a2.044,2.044,0,0,0,2.042,2.042H80.29A2.044,2.044,0,0,0,82.332,446v-1Z' transform='translate(-53.459 -424.81)' /> </g> </svg> "),
				"#",
				"%23"
			);
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			transition: $transition-base;
		}

		&:hover {
			&::before {
				background-image: str-replace(
					url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 22.41 23.23' fill='#{$link-hover-color}'> <g transform='translate(-9)'> <path d='M29.371,145H11.042A2.044,2.044,0,0,0,9,147.042v8.167a2.044,2.044,0,0,0,2.042,2.042h18.33a2.044,2.044,0,0,0,2.042-2.042v-8.167A2.044,2.044,0,0,0,29.371,145Zm-14.984,7.184-.75,0v1.57a.681.681,0,0,1-1.361,0l-.01-5.17a.68.68,0,0,1,.681-.683h1.44a2.14,2.14,0,1,1,0,4.28Zm5.842,2.089c-.405.007-1.419.011-1.462.011h0a.681.681,0,0,1-.681-.678c0-.018-.01-5.02-.01-5.02a.681.681,0,0,1,.681-.682h1.421c1.619,0,2.707,1.282,2.707,3.19C22.884,152.909,21.767,154.246,20.23,154.273Zm6.974-3.9a.681.681,0,1,1,0,1.361H25.969v1.935a.681.681,0,1,1-1.361,0v-5.134a.681.681,0,0,1,.681-.681h2.081a.681.681,0,1,1,0,1.361h-1.4v1.157Z' transform='translate(0 -138.421)' /> <path d='M239.767,239h-.74c0,.614,0,3.063.007,3.654.283,0,.586,0,.763-.007.909-.016,1.317-.925,1.317-1.818C241.113,240.4,241.016,239,239.767,239Z' transform='translate(-219.591 -228.156)' /> <path d='M111.8,239h-.758c0,.3,0,.625,0,.779,0,.18,0,.492,0,.782l.753,0a.779.779,0,1,0,0-1.558Z' transform='translate(-97.415 -228.156)' /> <path d='M82.125,5.218A3.4,3.4,0,0,0,81.4,4.043L78.571,1.062A3.417,3.417,0,0,0,76.1,0h-9.06A2.044,2.044,0,0,0,65,2.042V5.218Z' transform='translate(-53.459)' /> <path d='M65,445v1a2.044,2.044,0,0,0,2.042,2.042H80.29A2.044,2.044,0,0,0,82.332,446v-1Z' transform='translate(-53.459 -424.81)' /> </g> </svg> "),
					"#",
					"%23"
				);
			}
		}
	}
}
