@if $use-slideInLeft == true {
	@-webkit-keyframes slideInLeft {
		0% {
			// -webkit-transform: translate3d(-100%, 0, 0);
			visibility: visible;
		}

		100% {
			-webkit-transform: translate3d(0, 0, 0);
		}
	}

	@keyframes slideInLeft {
		0% {
			// transform: translate3d(-100%, 0, 0);
			visibility: visible;
		}

		100% {
			transform: translate3d(0, 0, 0);
		}
	}

	.slideInLeft {
		@include animate-prefixer(animation-name, slideInLeft);
	}
}
