@if $use-slideOutUp == true {

	@-webkit-keyframes slideOutUp {
		0% {
			-webkit-transform: translate3d(0, 0, 0);
			visibility: visible;
		}

		100% {
			-webkit-transform: translate3d(0, -100%, 0);
		}
	}

	@keyframes slideOutUp {
		0% {
			transform: translate3d(0, 0, 0);
			visibility: visible;
		}

		100% {
			transform: translate3d(0, -100%, 0);
		}
	}

	.slideOutUp {
		@include animate-prefixer(animation-name, slideOutUp);
	}

}
