@if $use-slideInUp == true {

	@-webkit-keyframes slideInUp {
		0% {
			-webkit-transform: translate3d(0, 100%, 0);
			visibility: visible;
		}

		100% {
			-webkit-transform: translate3d(0, 0, 0);
		}
	}

	@keyframes slideInUp {
		0% {
			transform: translate3d(0, 100%, 0);
			visibility: visible;
		}

		100% {
			transform: translate3d(0, 0, 0);
		}
	}

	.slideInUp {
		@include animate-prefixer(animation-name, slideInUp);
	}

}
