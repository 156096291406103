.app-hero {
	overflow-x: hidden;
	z-index: 0; // second stack layer
	&__content-wrapper {
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;

		.section {
			&__bg--image-container,
			&__bg--slider-container {
				background-color: $dark;
				z-index: -1; // third stack layer
			}

			&__gradient-linear {
				&::before {
					height: 50%;
					top: initial;
				}
			}

			&__bg--image {
				opacity: 0.3;
			}
		}
	}

	&__content {
		width: 100%;
		padding: calc(#{$header-max-height} + (#{$grid-gutter-width} * 0.5) + 2.5rem) 0;
		z-index: 1; // first stack layer.

		& > .container-fluid {
			& > .row {
				&:nth-child(2) {
					.row {
						& > .col {
							&:not(:first-child) {
								.branch__logo-container {
									transform: scale(1.25);
								}
							}
						}
					}
				}
			}
		}
	}

	&__title,
	&__subtitle,
	&__slogan {
		color: inherit;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 0;
		line-height: 1;
	}
	&__title {
		font-family: $font-family-cinzel;
		font-weight: $font-weight-base;
	}
	&__subtitle {
		font-weight: $font-weight-bolder;
		font-family: $font-family-montserrat;
	}
	&__slogan-container {
		position: relative;
		margin-top: map-get($spacers, 3);
		z-index: 1; // first stack layer.
	}
	&__slogan {
		width: 100%;
		height: auto;
		object-fit: cover;
		object-position: center;
		z-index: 1; // first stack layer.
	}

	@media screen and (max-height: 750px) {
		&__content-wrapper {
			height: 100%;
			min-height: 100%;
		}
	}
}
