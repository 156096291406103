.news-card {
	position: relative;
	z-index: 0; // second stack layer.
	color: $news-card-color;
	height: 100%;
	font-family: $font-family-montserrat #{"/*rtl:prepend:" $font-family-cairo ",*/"};
	text-transform: uppercase;
	transition: $transition-base;
	overflow: hidden;

	&__fade {
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background: rgba($news-card-bg-color, 0.75);
		}
	}

	&__bg {
		&--image-container {
			display: block;
			position: absolute;
			top: 0;
			left: 50%;
			bottom: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			transform: translateX(-50%);
			z-index: -1; // first stack layer.
			& + * {
				z-index: 1; // third stack layer.
			}
		}

		&--image {
			height: 100%;
			width: 100%;
			opacity: 1;
			object-fit: cover;
			object-position: center;
			transition: $transition-base;
		}
	}

	&__content {
		padding: $news-card-padding;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		overflow: hidden;
	}

	&__time {
		font-weight: $font-weight-light;
		color: inherit;
		font-size: $font-size-sm;
		margin-bottom: map-get($spacers, 2);
	}
	&__title {
		&,
		a,
		a:hover,
		a:focus,
		a:active {
			color: inherit;
			font-family: inherit;
		}
		font-weight: $font-weight-base;
		margin-bottom: 3.125rem;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&__call-to-action {
		align-self: flex-end;
		margin-top: auto;
		font-weight: $font-weight-bolder;
		position: relative;
		transition: $transition-base;
		padding: 0.25rem 1rem;
		font-size: $font-size-base * 1.125;
		&,
		&:hover,
		&:focus,
		&:active {
			color: inherit;
		}
		&::before {
			content: "";
			position: absolute;
			width: 65%;
			height: $border-width;
			background-color: $news-card-color;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			z-index: -1;
			transition: $transition-base;
		}
	}

	&:hover,
	&:active,
	&:focus {
		.news-card__bg--image {
			transition: $transition-base;
			transform: scale(1.1);
			filter: blur(8px);
			-webkit-filter: blur(8px);
		}

		.news-card__call-to-action {
			&::before {
				width: calc(100% - (1rem * 2)); // 100% - padding right and padding left
			}
		}
	}
}
