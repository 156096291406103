.app-article-share {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.25rem 0;
	color: $body-color;

	border-top: $border-width $border-style $border-color;
	border-bottom: $border-width $border-style $border-color;

	&__title {
		font-weight: $font-weight-light;
		color: inherit;
		margin-bottom: 0;
		text-transform: capitalize;
		font-family: inherit;
	}
}
