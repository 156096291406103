.campus {
	padding: $campus-padding-y $campus-padding-y;
	background-color: $body-bg;
	color: $body-color;
	transition: $transition-base;

	&--shadow-sm {
		@include box-shadow($box-shadow-sm);
		&.campus--with-hover {
			&:hover {
				@include box-shadow($box-shadow);
			}
		}
	}

	&--shadow-md {
		@include box-shadow($box-shadow);
		&.campus--with-hover {
			&:hover {
				@include box-shadow($box-shadow-lg);
			}
		}
	}

	&--shadow-lg {
		@include box-shadow($box-shadow-lg);
	}

	&--shadow-none {
		@include box-shadow(none !important);
	}

	&__header,
	&__body,
	&__footer {
		padding: $campus-header-padding-y 0;
	}
	&__body,
	&__footer {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 100%;
			height: $border-width;
			background-color: $border-color;
			display: none;
			visibility: hidden;
		}
	}

	&__header {
		& + .campus__body,
		& + .campus__footer {
			&::before {
				display: block;
				visibility: visible;
			}
		}
	}

	&__body {
		& + .campus__footer {
			&::before {
				display: block;
				visibility: visible;
			}
		}
	}
	&__footer {
	}

	&__title,
	&__subtitle {
		margin-bottom: 0;
		color: inherit;
	}

	&__title {
		font-weight: $font-weight-light;
		text-transform: uppercase;
	}
	&__subtitle {
		font-weight: $font-weight-light;
		text-transform: capitalize;
	}
	&__call-to-action-list {
		display: flex;
		flex-direction: column;
	}
	&__call-to-action {
		padding-right: 0;
		padding-left: 0;
		font-weight: $font-weight-light;
		&:not(:last-of-type) {
			margin-bottom: $campus-padding-x / 2;
		}
	}
}

@each $color, $color-value in $theme-colors {
	.campus--bg-#{$color} {
		@if $color == "transparent" {
			color: $body-color;
			background-color: transparent;
		} @else {
			background-color: $color-value;
			color: color-yiq($color-value);
		}

		.campus__body,
		.campus__footer {
			&::before {
				@if $color == "transparent" {
					background-color: $border-color;
				} @else {
					background-color: color-yiq($color-value, $border-color);
				}
			}
		}
		.campus__call-to-action {
			@if $color == "transparent" {
				color: $link-color;
			} @else {
				color: color-yiq($color-value, $link-color);
			}
		}
	}

	.campus__title--#{$color} {
		color: $color-value;
	}
}
