@if $use-slideOutRight == true {

	@-webkit-keyframes slideOutRight {
		0% {
			-webkit-transform: translate3d(0, 0, 0);
			visibility: visible;
		}

		100% {
			-webkit-transform: translate3d(100%, 0, 0);
		}
	}

	@keyframes slideOutRight {
		0% {
			transform: translate3d(0, 0, 0);
			visibility: visible;
		}

		100% {
			transform: translate3d(100%, 0, 0);
		}
	}

	.slideOutRight {
		@include animate-prefixer(animation-name, slideOutRight);
	}

}
