.search-result {
	padding: $search-result-padding;
	border-bottom: $border-width $border-style $border-color;
	margin: $search-result-margin;
	&__title {
		text-transform: uppercase;
		font-weight: $font-weight-base;
		&,
		a {
			transition: $transition-base;
		}
	}
	&__content {
		@extend .section__content;
	}
	&__call_to_action {
	}
}
