.app-main-header {
	position: relative;
	color: $app-main-header-color;
	text-align: center;
	padding: calc((#{$header-max-height} * 1.75) + #{$app-main-header-padding-y}) $app-main-header-padding-x $app-main-header-padding-y;

	&__title,
	&__subtitle,
	&__back,
	&__back:hover {
		color: inherit;
	}

	&__title {
		font-family: $font-family-cinzel;
		text-transform: uppercase;
		font-weight: $font-weight-base;
		word-break: break-word;
	}
	&__subtitle {
		text-transform: uppercase;
		font-weight: $font-weight-lighter;
		margin-bottom: 0;
		font-family: $font-family-base;
	}
	&__query-text {
		margin-left: map-get($spacers, 3);
	}
	&__back {
		font-weight: $font-weight-light;
	}

	&__logo-container {
		text-align: center;
		color: $app-main-header-color !important;
		display: block;
		hr {
			border-top-color: $primary;
			width: 50%;
			margin-right: auto;
			margin-left: auto;
			margin-top: #{$hr-margin-y * 0.5};
		}
	}
	&__logo {
		width: auto;
		height: 60px;
		object-fit: cover;
		object-position: center;
		+ p {
			margin-top: map-get($spacers, 2);
			margin-bottom: 0;
			font-weight: $font-weight-light;
		}
	}

	.breadcrumb {
		justify-content: center;
	}
}
