.nav {
	&__list {
		display: flex;
		width: 100%;
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		color: inherit;

		&--horizontal {
			flex-direction: row;
			flex-wrap: nowrap;
		}

		&--vertical {
			flex-direction: column;
			flex-wrap: wrap;
		}
	}
	&__list-item,
	&__link {
		padding-bottom: $nav-link-padding-y;
		padding-top: $nav-link-padding-y;
		text-transform: $nav-text-transform;
		font-weight: $nav-font-weight;
		font-size: $nav-font-size;
	}
	&__list-item {
		&.nav-item {
			padding-top: 0;
			padding-bottom: 0;
		}
		&:hover {
			&,
			a {
				color: $nav-hover-color;
			}
		}
		&:not(:last-child) {
			border-bottom: $nav-border-width $border-style $nav-border-color;
		}
	}
	&__link {
		color: inherit;
		transition: $transition-base;

		&--active,
		&.active,
		&:hover,
		&:focus,
		&:active {
			color: $nav-active-color;
		}

		&--active,
		&.active {
			position: relative;
			padding-left: calc(#{$nav-active-icon-width} + #{$spacer / 2});
			&:before {
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: $nav-active-icon-width;
				height: $nav-active-icon-height;
				background-size: $nav-active-icon-width $nav-active-icon-height;
				background-position: center;
				background-repeat: no-repeat;
				background-image: $nav-active-icon;
			}
		}
	}
}
