.school-system-section {
	.section {
		&__content-wrapper {
			background-color: transparent;
		}
		&__content {
			max-height: 12.5rem;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 0.25rem;
				&:hover {
					width: 0.5rem;
				}
			}

			&::-webkit-scrollbar-track {
				background-color: darken($light, $emphasized-link-hover-darken-percentage);
				border-radius: $border-radius-lg;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: $border-radius-lg;
				background-color: rgba($gray-700, 0.5);
				cursor: pointer;
				&:hover {
					background-color: $gray-700;
				}
			}
		}
	}
	.nav {
		&__list-item {
			&:not(:last-child) {
				border-bottom-color: rgba($nav-border-color, 0.2);
			}
		}
	}
}
