#school-about-page {
	.app-article {
		padding-bottom: 0;
	}
	.section {
		&__content-wrapper {
			padding-right: #{$section-padding-x * 0.5};
			padding-left: #{$section-padding-x * 0.5};
		}
	}
}
