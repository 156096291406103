// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
	display: inline-flex;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	color: $body-color;
	text-align: center;
	text-decoration: if($link-decoration == none, null, none);
	white-space: $btn-white-space;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: $btn-border-width solid transparent;
	text-transform: $btn-text-transform;
	align-items: center;
	justify-content: flex-start;
	@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
	@include transition($btn-transition);

	@include hover() {
		color: $body-color;
		text-decoration: none;
		@include transition($btn-transition);
		&:not(.btn-prevent-icon-hover) {
			.btn-icon {
				margin-left: #{$btn-padding-x * 2};
				margin-right: 0;
				&--reverse {
					margin-left: 0;
					margin-right: #{$btn-padding-x * 2};
				}
			}

			&.btn-with-arrow-icon {
				&::after {
					margin-left: #{$btn-padding-x * 2};
					margin-right: 0;
				}

				&--reverse {
					&::after {
						margin-left: 0;
						margin-right: #{$btn-padding-x * 2};
					}
				}

				&--down {
					&::after {
						margin-right: $btn-padding-x;
						margin-left: $btn-padding-x;
						margin-bottom: 0;
						margin-top: #{$btn-padding-x * 2};
					}
				}
				&--up {
					&::after {
						margin-right: $btn-padding-x;
						margin-left: $btn-padding-x;
						margin-top: 0;
						margin-bottom: #{$btn-padding-x * 2};
					}
				}
			}
		}
	}

	// prevent pagination links from hover animation
	.btn-icon {
		@include transition(margin $transition-base-time $transition-base-function);
	}
	.btn-icon--reverse {
		transform: rotateY(180deg);
	}

	&:not(.btn-prevent-icon-hover) {
		.btn-icon {
			margin-left: $btn-padding-x;
			margin-right: $btn-padding-x;
			&--reverse {
				margin-left: $btn-padding-x;
				margin-right: $btn-padding-x;
			}
		}
	}

	&:focus,
	&.focus {
		// outline: 0;
		box-shadow: $btn-focus-box-shadow;
		&:not(.btn-prevent-icon-hover) {
			.btn-icon {
				margin-left: #{$btn-padding-x * 2};
				margin-right: 0;
				&--reverse {
					margin-left: 0;
					margin-right: #{$btn-padding-x * 2};
				}
			}
			&.btn-with-arrow-icon {
				&::after {
					margin-left: #{$btn-padding-x * 2};
					margin-right: 0;
				}

				&--reverse {
					&::after {
						margin-left: 0;
						margin-right: #{$btn-padding-x * 2};
					}
				}

				&--down {
					&::after {
						margin-right: $btn-padding-x;
						margin-left: $btn-padding-x;
						margin-bottom: 0;
						margin-top: #{$btn-padding-x * 2};
					}
				}
				&--up {
					&::after {
						margin-right: $btn-padding-x;
						margin-left: $btn-padding-x;
						margin-top: 0;
						margin-bottom: #{$btn-padding-x * 2};
					}
				}
			}
		}
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		opacity: $btn-disabled-opacity;
		@include box-shadow(none);
	}

	&:not(:disabled):not(.disabled) {
		cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

		&:active,
		&.active {
			@include box-shadow($btn-active-box-shadow);

			&:focus {
				@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
			}
		}
	}

	&.btn-with-arrow-icon {
		position: relative;
		&::after {
			content: "";
			top: 0;
			right: 0;
			width: 1em;
			height: 1em;
			background-size: 1em 1em;
			background-position: center;
			background-repeat: no-repeat;

			margin-left: $btn-padding-x;
			margin-right: $btn-padding-x;

			@include transition(margin $transition-base-time $transition-base-function);
		}

		&--down {
			&::after {
				transform: rotate(90deg);
				margin-top: $btn-padding-x;
				margin-bottom: $btn-padding-x;
			}
		}
		&--up {
			&::after {
				transform: rotate(180deg);
				margin-top: $btn-padding-x;
				margin-bottom: $btn-padding-x;
			}
		}
	}
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}

@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
		@include button-outline-variant($value);
	}
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
	font-weight: $btn-font-weight;
	color: $link-color;
	text-decoration: $link-decoration;

	@include hover() {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}

	&:focus,
	&.focus {
		text-decoration: $link-hover-decoration;
	}

	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
		pointer-events: none;
	}

	// No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
	&:not(.btn-prevent-icon-hover) {
		.btn-icon {
			margin-left: $btn-padding-x;
			margin-right: $btn-padding-x;
			&--reverse {
				margin-left: $btn-padding-x;
				margin-right: $btn-padding-x;
			}
		}
		&.btn-with-arrow-icon {
			&::after {
				margin-left: $btn-padding-x;
				margin-right: $btn-padding-x;
			}

			&--down,
			&--up {
				&::after {
					margin-bottom: $btn-padding-x;
					margin-top: $btn-padding-x;
				}
			}
		}
	}
	@include hover() {
		&:not(.btn-prevent-icon-hover) {
			.btn-icon {
				margin-left: #{$btn-padding-x * 2};
				margin-right: 0;
				&--reverse {
					margin-left: 0;
					margin-right: #{$btn-padding-x * 2};
				}
			}
			&.btn-with-arrow-icon {
				&::after {
					margin-left: #{$btn-padding-x * 2};
					margin-right: 0;
				}

				&--reverse {
					&::after {
						margin-left: 0;
						margin-right: #{$btn-padding-x * 2};
					}
				}
				&--down {
					&::after {
						margin-right: $btn-padding-x;
						margin-left: $btn-padding-x;
						margin-bottom: 0;
						margin-top: #{$btn-padding-x * 2};
					}
				}
				&--up {
					&::after {
						margin-right: $btn-padding-x;
						margin-left: $btn-padding-x;
						margin-top: 0;
						margin-bottom: #{$btn-padding-x * 2};
					}
				}
			}
		}
	}
}

.btn-sm {
	@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
	&:not(.btn-prevent-icon-hover) {
		.btn-icon {
			margin-left: $btn-padding-x;
			margin-right: $btn-padding-x;
			&--reverse {
				margin-left: $btn-padding-x;
				margin-right: $btn-padding-x;
			}
		}
		&.btn-with-arrow-icon {
			&::after {
				margin-left: $btn-padding-x;
				margin-right: $btn-padding-x;
			}
			&--down,
			&--up {
				&::after {
					margin-bottom: $btn-padding-x;
					margin-top: $btn-padding-x;
				}
			}
		}
	}

	@include hover() {
		&:not(.btn-prevent-icon-hover) {
			.btn-icon {
				margin-left: #{$btn-padding-x * 2};
				margin-right: 0;
				&--reverse {
					margin-left: 0;
					margin-right: #{$btn-padding-x * 2};
				}
			}
			&.btn-with-arrow-icon {
				&::after {
					margin-left: #{$btn-padding-x * 2};
					margin-right: 0;
				}

				&--reverse {
					&::after {
						margin-left: 0;
						margin-right: #{$btn-padding-x * 2};
					}
				}

				&--down {
					&::after {
						margin-right: $btn-padding-x;
						margin-left: $btn-padding-x;
						margin-bottom: 0;
						margin-top: #{$btn-padding-x * 2};
					}
				}
				&--up {
					&::after {
						margin-right: $btn-padding-x;
						margin-left: $btn-padding-x;
						margin-top: 0;
						margin-bottom: #{$btn-padding-x * 2};
					}
				}
			}
		}
	}
}

//
// Block button
//

.btn-block {
	display: inline-flex;
	width: 100%;

	// Vertically space out multiple block buttons
	+ .btn-block {
		margin-top: $btn-block-spacing-y;
	}
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}
