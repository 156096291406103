// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
	$background,
	$border,
	$hover-background: darken($background, 7.5%),
	$hover-border: darken($border, 10%),
	$active-background: darken($background, 10%),
	$active-border: darken($border, 12.5%)
) {
	color: color-yiq($background);
	@include gradient-bg($background);
	border-color: $border;
	@include box-shadow($btn-box-shadow);

	@include hover() {
		color: color-yiq($hover-background);
		@include gradient-bg($hover-background);
		border-color: $hover-border;
	}

	.btn-icon {
		fill: color-yiq($background);
	}

	&:focus,
	&.focus {
		color: color-yiq($hover-background);
		@include gradient-bg($hover-background);
		border-color: $hover-border;
		@if $enable-shadows {
			@include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5));
		} @else {
			// Avoid using mixin so we can pass custom focus shadow properly
			box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
		}
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		color: color-yiq($background);
		background-color: $background;
		border-color: $border;
		// Remove CSS gradients if they're enabled
		@if $enable-gradients {
			background-image: none;
		}
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		color: color-yiq($active-background);
		background-color: $active-background;
		@if $enable-gradients {
			background-image: none; // Remove the gradient for the pressed/active state
		}
		border-color: $active-border;

		&:focus {
			@if $enable-shadows and $btn-active-box-shadow != none {
				@include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5));
			} @else {
				// Avoid using mixin so we can pass custom focus shadow properly
				box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
			}
		}
	}

	&.btn-with-arrow-icon {
		&::after {
			background-image: str-replace(
				url("data:image/svg+xml,<svg width='1em' height='1em' viewBox='0 0 16 16' fill='#{color-yiq($background)}' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'></path> </svg>"),
				"#",
				"%23"
			);
		}
	}
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
	color: $color;
	border-color: $color;

	@include hover() {
		color: $color-hover;
		background-color: $active-background;
		border-color: $active-border;

		&.btn-with-arrow-icon {
			&::after {
				background-image: str-replace(
					url("data:image/svg+xml,<svg width='1em' height='1em' viewBox='0 0 16 16' fill='#{$color-hover}' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'></path> </svg>"),
					"#",
					"%23"
				);
			}
		}
	}

	.btn-icon {
		fill: $color;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $color;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		color: color-yiq($active-background);
		background-color: $active-background;
		border-color: $active-border;

		&:focus {
			@if $enable-shadows and $btn-active-box-shadow != none {
				@include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.5));
			} @else {
				// Avoid using mixin so we can pass custom focus shadow properly
				box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
			}
		}
	}

	&.btn-with-arrow-icon {
		&::after {
			background-image: str-replace(
				url("data:image/svg+xml,<svg width='1em' height='1em' viewBox='0 0 16 16' fill='#{$color}' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'></path> </svg>"),
				"#",
				"%23"
			);
		}
	}
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
	padding: $padding-y $padding-x;
	@include font-size($font-size);
	line-height: $line-height;
	// Manually declare to provide an override to the browser default
	@include border-radius($border-radius, 0);
}
