$animate-name: 'zoomOutLeft';

@mixin zoomOutLeft($prefix: '') {
	40% {
		opacity: 1;
		#{$prefix}transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
	}

	100% {
		opacity: 0;
		#{$prefix}transform: scale3d(.1) translate3d(-2000px, 0, 0);
		#{$prefix}transform-origin: left center;
	}
}

@if $use-zoomOutLeft == true {

	@-webkit-keyframes #{$animate-name} {
		@include zoomOutLeft('-webkit-');
	}

	@keyframes #{$animate-name} {
		@include zoomOutLeft();
	}

	.#{$animate-name} {
		@include animate-prefixer(animation-name, $animate-name);
	}

}
