.social {
	&__list {
		display: flex;
		list-style-type: none;
		margin: 0;

		&--horizontal {
			flex-direction: row;
			.social__list-item {
				&:not(:last-child) {
					margin-right: 1.5rem;
				}
			}
		}

		&--vertical {
			flex-direction: column;
			.social__list-item {
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 1.5rem;
				}
			}
		}
	}

	&__list-item,
	&__link {
		text-align: center;
		color: $body-color;
		transition: $transition-base;
	}

	&__list-item {
		&:not(:last-child) {
			margin-right: 1.5rem;
		}
	}
}

@each $color, $value in $theme-colors {
	.social__list-item--#{$color} {
		&,
		.social__link {
			color: $value;
			&:hover,
			&:focus,
			&:active {
				color: $value;
				// color: darken($value, $emphasized-link-hover-darken-percentage);
			}
		}
	}
}
