.app-article {
	position: relative;
	height: 100%; // for children with sticky positioning.
	padding-top: #{$grid-gutter-width * 4.6875};
	padding-bottom: #{$grid-gutter-width * 4.6875};

	&__apply-now {
		&-container {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}
