.process {
	&__list {
		margin: 0;
		list-style: none;
	}
	&__list-item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-bottom: $spacer;
		position: relative;

		&:not(:last-of-type) {
			&::after {
				content: "";
				position: absolute;
				right: calc(-1 * #{$process-padding-x});
				top: calc(#{$process-title-font-size * $line-height-sm} / 2);
				height: $border-width;
				border-top: $border-width dashed $border-color;
				width: 100%;
				z-index: -1;

				@include media-breakpoint-down(sm) {
					height: 100%;
					width: $border-width;
					border-top: 0;
					border-left: $border-width dashed $border-color;
					right: initial;
					left: calc(#{$process-padding-x / 2});
					top: calc(100% - (#{$process-title-font-size * $line-height-sm} / 2));
				}
			}
		}

		&:not(:first-of-type):not(:last-of-type) {
			&::after {
				width: calc(100% - #{$process-padding-x});
				@include media-breakpoint-down(sm) {
					width: $border-width;
					height: calc(100% - #{$process-padding-x});
				}
			}

			@include media-breakpoint-up(md) {
				padding-right: $process-padding-x;
				padding-left: $process-padding-x;
			}
		}

		&:first-of-type {
			@include media-breakpoint-up(md) {
				padding-right: $process-padding-x;
			}
		}

		&:last-of-type {
			@include media-breakpoint-up(md) {
				padding-left: $process-padding-x;
			}
		}
	}

	&__content {
		font-weight: $font-weight-light;
		font-size: $process-font-size;
		color: $process-color;
		background-color: $process-bg;
		z-index: 0;
	}

	&__title {
		margin-bottom: 0;
		line-height: $line-height-sm;
		background-color: $process-bg;
		color: $process-title-color;
		font-size: $process-title-font-size;
		z-index: 0;
		word-break: break-all;
	}
}
