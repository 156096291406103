.section {
	position: relative;

	&--zigzag {
		&:not(:first-of-type):not(:last-of-type) {
			padding-bottom: 3.125rem;
			padding-top: 3.125rem;
		}

		&:first-of-type {
			padding-bottom: 6.25rem;
		}
		&:last-of-type {
			padding-top: 6.25rem;
		}

		&:nth-of-type(odd) {
			.section__media-image {
				@extend .section__media-image--position-right;
			}
		}

		&:nth-of-type(even) {
			.section__media-image {
				@extend .section__media-image--position-left;
			}
		}
	}

	&__gradient {
		&-linear {
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				z-index: 0;
				background: linear-gradient(
					180deg,
					rgba($light, 0),
					rgba($light, 0.05),
					rgba($light, 0.1),
					rgba($light, 0.15),
					rgba($light, 0.2),
					rgba($light, 0.25),
					rgba($light, 0.3),
					rgba($light, 0.35),
					rgba($light, 0.4),
					rgba($light, 0.45),
					rgba($light, 0.5),
					rgba($light, 0.55),
					rgba($light, 0.6),
					rgba($light, 0.65),
					rgba($light, 0.7),
					rgba($light, 0.75),
					rgba($light, 0.8),
					rgba($light, 0.85),
					rgba($light, 0.9),
					rgba($light, 0.95),
					rgba($light, 1),
					rgba($light, 1)
				);
			}
		}
	}

	&__fade {
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background: rgba($white, 0.8);
		}
	}

	&__bg {
		&--svg-container,
		&--image-container,
		&--slider-container,
		&--map-container {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			& + * {
				z-index: 10;
			}
		}

		&--map-container {
			& + * {
				@include media-breakpoint-down(md) {
					padding-bottom: 25vh !important;
				}
				@include media-breakpoint-down(sm) {
					padding-bottom: 50vh !important;
				}
			}
		}

		&--svg,
		&--image,
		&--slider,
		&--map {
			height: 100%;
			width: 100%;
			opacity: 1;
			object-fit: cover;
			object-position: center;
			svg {
				width: 100%;
				height: 100%;
			}
			iframe {
				width: 100%;
				height: 100%;
				border: 0;
				pointer-events: none;
				&.clicked {
					pointer-events: auto;
				}
			}
		}
	}

	&__content-wrapper-list {
		.section__content-wrapper {
			&:first-child {
				padding-top: $section-padding-y;
				padding-bottom: #{$section-padding-y * 0.25};
			}
			&:last-child {
				padding-top: #{$section-padding-y * 0.25};
				padding-bottom: $section-padding-y;
			}
			&:not(:first-child):not(:last-child) {
				padding-top: #{$section-padding-y * 0.25};
				padding-bottom: #{$section-padding-y * 0.25};
			}
		}
	}
	&__content-wrapper {
		position: relative;
		min-width: 1px;
		max-width: 100%;
		min-height: 1px;
		word-wrap: break-word;
		background-clip: border-box;
		border-radius: 0;
		transition: $transition-base;
		background: inherit;
		margin-right: auto;
		margin-left: auto;
		z-index: 25;
		padding: $section-padding-y $section-padding-x;

		@include media-breakpoint-down(md) {
			padding: #{$section-padding-y / 2} #{$section-padding-x / 2};
		}
		@include media-breakpoint-down(sm) {
			padding: #{$section-padding-y / 3} #{$section-padding-x / 3};
		}
	}

	&__content {
		font-weight: $font-weight-light;
		line-height: $line-height-lg;
		margin-bottom: $spacer;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $font-family-base;
			font-weight: $font-weight-light;
			color: inherit;
		}

		ol {
			margin-left: 1rem;
		}
		ul {
			margin-left: calc(0.375rem + #{$spacer});
			li {
				padding: 0;
				margin: 0;

				&,
				a,
				button {
					font-weight: $font-weight-light;
				}
				a,
				button {
					padding: 0;
				}
			}
		}

		dl {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: flex-start;
			margin-top: $spacer;

			dt,
			dd {
				text-transform: uppercase;
				font-size: $font-size-sm;
			}
		}

		address {
			padding-left: calc(13.42px + 7px); // icon width + some space
			position: relative;
			@include transition($btn-transition);

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 13.42px;
				height: 18.528px;
				background-position: center;
				background-repeat: no-repeat;
				background-image: str-replace(
					url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='13.42' height='18.528' viewBox='0 0 13.42 18.528'><g transform='translate(-70.573)'><g transform='translate(70.573)'><path d='M77.283,0a6.718,6.718,0,0,0-6.71,6.71c0,4.592,6,11.333,6.261,11.617a.6.6,0,0,0,.9,0c.256-.285,6.261-7.026,6.261-11.617A6.718,6.718,0,0,0,77.283,0Zm0,10.086A3.376,3.376,0,1,1,80.659,6.71,3.38,3.38,0,0,1,77.283,10.086Z' transform='translate(-70.573)' fill='#{$link-color}'/></g></g></svg>"),
					"#",
					"%23"
				);
				background-size: 13.42px 18px;
			}
			@include hover() {
				&,
				a {
					color: $link-hover-color;
					text-decoration: $link-hover-decoration;
					@include transition($btn-transition);
				}

				&:before {
					background-image: str-replace(
						url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='13.42' height='18.528' viewBox='0 0 13.42 18.528'><g transform='translate(-70.573)'><g transform='translate(70.573)'><path d='M77.283,0a6.718,6.718,0,0,0-6.71,6.71c0,4.592,6,11.333,6.261,11.617a.6.6,0,0,0,.9,0c.256-.285,6.261-7.026,6.261-11.617A6.718,6.718,0,0,0,77.283,0Zm0,10.086A3.376,3.376,0,1,1,80.659,6.71,3.38,3.38,0,0,1,77.283,10.086Z' transform='translate(-70.573)' fill='#{$link-hover-color}'/></g></g></svg>"),
						"#",
						"%23"
					);
				}
			}

			&,
			a {
				color: $link-color;
				font-weight: $font-weight-light;
				font-size: $font-size-sm;
				&::first-letter {
					text-transform: uppercase;
				}
			}
		}
	}

	&__time {
		font-weight: $font-weight-light;
		font-size: $font-size-sm;
		margin-bottom: map-get($spacers, 2);
	}

	&__title {
		font-weight: $font-weight-light;
		text-transform: uppercase;
		line-height: 0.9;
		word-break: break-word;
		&--sm {
			display: flex;
			align-items: center;
			text-transform: uppercase;
			font-weight: $font-weight-lighter;
			font-family: $font-family-base;
			color: $body-color;
			font-size: 50%;
		}
	}

	&__logo {
		&-container {
			display: flex;
			align-items: center;
			margin-bottom: $spacer;
		}
		&-title {
			color: $body-color;
			font-family: $font-family-montserrat;
			font-weight: $font-weight-light;
			text-transform: capitalize;
			margin: 0;
		}

		&-img {
			margin-right: $spacer;
			width: 36px;
			height: auto;
		}
	}

	&__subtitle {
		font-weight: $font-weight-base;
		text-transform: capitalize;
	}

	&__image {
		width: 100%;
		min-height: fit-content;
		height: fit-content;
		overflow: hidden;
		z-index: 4;

		img {
			transition: $transition-collapse;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		&--top {
			margin-bottom: calc(-#{$section-padding-y});
			margin-right: auto;
			margin-left: auto;
		}

		&--left {
			margin-right: calc(-#{$section-padding-x} - (#{$grid-gutter-width} * 2));
			margin-top: auto;
			margin-bottom: auto;
		}

		&--bottom {
			margin-top: calc(-#{$section-padding-y});
			margin-right: auto;
			margin-left: auto;
		}

		&--right {
			margin-left: calc(-#{$section-padding-x} - (#{$grid-gutter-width} * 2));
			margin-top: auto;
			margin-bottom: auto;
		}

		&--border {
			&:after {
				content: "";
				display: block;
				position: absolute;
				width: calc(100% - (#{$section-padding-x} * 2));
				height: calc(100% - (#{$section-padding-y} * 2));
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: transparent;
				border: $border-width $border-style $border-color;
				transition: $transition-base;
			}
		}
	}

	&__media {
		&-container {
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 100%;
		}
		&-image {
			display: flex;
			height: auto;
			min-height: 100%;
			max-height: 31.25rem; // 500px
			width: 100%;
			object-fit: cover;
			object-position: center;

			@include media-breakpoint-down(md) {
				max-height: calc(100vw * 0.5);
			}

			@include media-breakpoint-up(md) {
				&--float {
					position: absolute;
					&.section__media-image--position {
						&-center {
							left: 50%;
							top: 50%;
							transform: translate(-50%);
							align-items: center;
							justify-content: center;
						}
						&-top {
							top: 0;
							left: 50%;
							transform: translateX(-50%);
							justify-content: center;
						}
						&-right {
							right: 0;
							top: 50%;
							transform: translateY(-50%);
							align-items: center;
						}
						&-bottom {
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
							justify-content: center;
						}
						&-left {
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							align-items: center;
						}
						&-right-top {
							right: 0;
							top: 0;
							justify-content: flex-end;
						}
						&-right-bottom {
							right: 0;
							bottom: 0;
							align-items: flex-end;
							justify-content: flex-end;
						}
						&-left-top {
							left: 0;
							top: 0;
						}
						&-left-bottom {
							left: 0;
							bottom: 0;
							align-items: flex-end;
						}
					}
				}
			}

			&--position {
				&-center,
				&-top,
				&-right,
				&-left,
				&-right-top,
				&-right-bottom,
				&-left-top,
				&-left-bottom {
					@include media-breakpoint-down(md) {
						object-position: center;
					}
				}
				&-center {
					object-position: center center;
					align-items: center;
					justify-content: center;
				}
				&-top {
					object-position: center top;
					justify-content: center;
				}
				&-right {
					object-position: right center;
					align-items: center;
				}
				&-bottom {
					object-position: center bottom;
					justify-content: center;
				}
				&-left {
					object-position: left center;
					align-items: center;
				}
				&-right-top {
					object-position: right top;
					justify-content: flex-end;
				}
				&-right-bottom {
					object-position: right bottom;
					align-items: flex-end;
					justify-content: flex-end;
				}
				&-left-top {
					object-position: left top;
				}
				&-left-bottom {
					object-position: left bottom;
					align-items: flex-end;
				}
			}
		}
	}
}

@each $color, $color-value in $theme-colors {
	.section--bg-#{$color} {
		background-color: $color-value;
		color: color-yiq($color-value, $black);

		.section__call-to-action {
			@if $color-value==transparent {
				&.btn-link {
					color: color-yiq($color-value, $primary, $primary);
					.btn-icon {
						.fill {
							fill: color-yiq($color-value, $primary, $primary);
						}
					}
				}
			} @else {
				&.btn-link {
					color: color-yiq($color-value);
					.btn-icon {
						.fill {
							fill: color-yiq($color-value);
						}
					}
				}
			}
		}

		.section__content-wrapper {
			@if $color-value != transparent {
				background-color: $color-value;
			}
		}
	}
	.section__time--#{$color} {
		color: $color-value !important;
	}
	.section__subtitle--#{$color} {
		color: $color-value !important;
	}

	.section__fade--#{$color} {
		background-color: $color-value;

		&::after {
			background: rgba($color-value, 0.5);
		}

		@each $opacity, $opacity-value in $fade-opacity {
			&-#{$opacity} {
				background-color: $color-value;

				&::after {
					background: rgba($color-value, $opacity-value);
				}
			}
		}
	}

	.section__gradient-linear--#{$color} {
		background-color: $color-value;

		&::before {
			background: linear-gradient(
				180deg,
				rgba($color-value, 0),
				rgba($color-value, 0.05),
				rgba($color-value, 0.1),
				rgba($color-value, 0.15),
				rgba($color-value, 0.2),
				rgba($color-value, 0.25),
				rgba($color-value, 0.3),
				rgba($color-value, 0.35),
				rgba($color-value, 0.4),
				rgba($color-value, 0.45),
				rgba($color-value, 0.5),
				rgba($color-value, 0.55),
				rgba($color-value, 0.6),
				rgba($color-value, 0.65),
				rgba($color-value, 0.7),
				rgba($color-value, 0.75),
				rgba($color-value, 0.8),
				rgba($color-value, 0.85),
				rgba($color-value, 0.9),
				rgba($color-value, 0.95),
				rgba($color-value, 1),
				rgba($color-value, 1)
			);
		}

		@each $angle, $angle-value in $gradient-angles {
			&-#{$angle} {
				background-color: $color-value;

				&::before {
					background: linear-gradient(
						$angle-value,
						rgba($color-value, 0),
						rgba($color-value, 0.05),
						rgba($color-value, 0.1),
						rgba($color-value, 0.15),
						rgba($color-value, 0.2),
						rgba($color-value, 0.25),
						rgba($color-value, 0.3),
						rgba($color-value, 0.35),
						rgba($color-value, 0.4),
						rgba($color-value, 0.45),
						rgba($color-value, 0.5),
						rgba($color-value, 0.55),
						rgba($color-value, 0.6),
						rgba($color-value, 0.65),
						rgba($color-value, 0.7),
						rgba($color-value, 0.75),
						rgba($color-value, 0.8),
						rgba($color-value, 0.85),
						rgba($color-value, 0.9),
						rgba($color-value, 0.95),
						rgba($color-value, 1),
						rgba($color-value, 1)
					);
				}
			}

			&-#{$angle}-reverse {
				background-color: $color-value;

				&::before {
					background: linear-gradient(
						-#{$angle-value},
						rgba($color-value, 0),
						rgba($color-value, 0.05),
						rgba($color-value, 0.1),
						rgba($color-value, 0.15),
						rgba($color-value, 0.2),
						rgba($color-value, 0.25),
						rgba($color-value, 0.3),
						rgba($color-value, 0.35),
						rgba($color-value, 0.4),
						rgba($color-value, 0.45),
						rgba($color-value, 0.5),
						rgba($color-value, 0.55),
						rgba($color-value, 0.6),
						rgba($color-value, 0.65),
						rgba($color-value, 0.7),
						rgba($color-value, 0.75),
						rgba($color-value, 0.8),
						rgba($color-value, 0.85),
						rgba($color-value, 0.9),
						rgba($color-value, 0.95),
						rgba($color-value, 1),
						rgba($color-value, 1)
					);
				}
			}
		}
	}
}
