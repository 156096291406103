.step {
	background-color: $step-bg-color;
	color: $step-color;
	border: $step-border-width $border-style $step-border-color;
	padding: $step-padding-y calc(#{$step-done-icon-bg-width} + #{$step-padding-x}) $step-padding-y $step-padding-x;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	transition: $transition-base;
	margin-bottom: $spacer;

	&:not(:last-child) {
		margin-right: $spacer;
	}

	&:not(:disabled):not(.step--disabled):not(.step--done) {
		&:hover {
			color: $step-hover-color;
			background-color: $step-hover-bg-color;
			border: $step-hover-border-width $border-style $step-hover-border-color;
			transition: $transition-base;
		}
	}

	&--active {
		color: $step-active-color;
		background-color: $step-active-bg-color;
		border: $step-active-border-width $border-style $step-active-border-color;
	}

	&--done,
	&--disabled {
		cursor: default;
		&,
		&:hover {
			color: $step-disabled-color;
			background-color: $step-disabled-bg-color;
			border: $step-disabled-border-width $border-style $step-disabled-border-color;
		}
	}

	&--disabled,
	&--done {
		pointer-events: none;
	}

	&--done {
		position: relative;
		&::after,
		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			color: $step-disabled-color;
			height: $step-done-icon-bg-height;
			width: $step-done-icon-bg-width;
		}
		&::after {
			z-index: 0;
			background-color: theme-color("primary-dark");
		}
		&::before {
			z-index: 1;
			background-image: $step-done-icon-bg;
			background-size: 18px 14px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&--block {
		width: 100%;
		display: flex;
	}

	&__number {
		font-size: #{$font-size-base * 2.8125} !important;
		line-height: 1;
		font-weight: $font-weight-light !important;
		font-family: $headings-font-family #{"/*rtl:prepend:" $font-family-cairo ",*/"} !important;

		& + .step__label {
			margin-left: map-get($spacers, 2);
		}
	}

	&__label {
		text-transform: capitalize;
		font-size: #{$font-size-base * 1.25} !important;
		font-weight: $font-weight-light !important;
	}
}
