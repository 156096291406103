#school-activities-events-single-page {
	.app-article {
		padding-bottom: 0;
		&-header {
			padding-right: $grid-gutter-width;
			padding-left: $grid-gutter-width;
			.section {
				&__content-wrapper {
					padding-top: 0;
					padding-bottom: 0;
				}
				&__title {
					line-height: $line-height-base;
				}
				&__media {
					&-image {
						min-height: 300px;
						max-height: 300px;
					}
				}
			}
		}
	}
}
