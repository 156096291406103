.logos {
	&__list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		list-style-type: none;
		margin: 0;

		&--horizontal {
			flex-direction: row !important;
			.logos__list-item {
				&:not(:last-child) {
					margin-right: 3.125rem;
				}
			}
		}

		&--vertical {
			flex-direction: column !important;
			.logos__list-item {
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 3.125rem;
				}
			}
		}
	}

	&__list-item {
		transition: $transition-base;
		height: 38px;
		width: auto;
		max-width: 100px;
		margin-top: 1rem;
		margin-bottom: 1rem;
		&:not(:last-child) {
			margin-right: 3.125rem;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: scale-down;
		object-position: center;
	}
}
