$bulletin-grade-padding-x: 12px;
$bulletin-grade-padding-y: 5px;
$bulletin-grade-bg-color: $primary;

$bulletin-padding-y: calc(#{$bulletin-grade-padding-y * 2} + #{$line-height-base * $font-size-sm});
$bulletin-padding-x: $bulletin-padding-y;
$bulletin-bg-color: $light;
$bulletin-border: $border-width $border-style $primary;

.bulletin-card {
	position: relative;
	background-color: $bulletin-bg-color;
	color: color-yiq($bulletin-bg-color);
	border: $bulletin-border;
	height: 100%;

	&__content-wrapper {
		padding: $bulletin-padding-y $bulletin-padding-x;
	}
	&__content {
		@extend .section__content;
	}
	&__title {
		font-weight: $font-weight-bold;
		&,
		a,
		a:hover,
		a:focus,
		a:active {
			color: inherit;
			font-family: inherit;
		}
	}
	&__time {
		font-weight: $font-weight-light;
		font-size: $font-size-sm;
		margin-bottom: $spacer;
	}
	&__grade {
		position: absolute;
		bottom: 0;
		padding: $bulletin-grade-padding-y $bulletin-grade-padding-x;
		background-color: $bulletin-grade-bg-color;
		color: color-yiq($bulletin-grade-bg-color, $primary);
		font-size: $font-size-sm;
		max-width: 100%;

		@extend .text-truncate;
	}
}

.bulletin-modal {
	.modal {
		&-body {
			position: relative;
			.close {
				z-index: 1;
				position: absolute;
				right: 0;
				color: $primary;
				margin: $bulletin-grade-padding-y $bulletin-grade-padding-x;
				padding: $bulletin-grade-padding-y $bulletin-grade-padding-x;
			}
		}
	}
	.bulletin-card {
		overflow-y: auto;
		&__content-wrapper {
			overflow: hidden;
			padding: calc(#{$bulletin-padding-y} * 1.5) calc(#{$bulletin-padding-x} * 1.5);
		}

		&__grade {
			bottom: initial;
		}
	}
}
