#about-us-page {
	.app-article {
		padding-top: 0;

		&-header {
			&-section {
				.section__content-wrapper {
					@include media-breakpoint-up(lg) {
						padding-bottom: map-get($spacers, 5);
					}
				}
			}
		}
	}
	.section--zigzag {
		.section__content-wrapper {
			padding-top: #{$section-padding-y / 2};
			padding-bottom: #{$section-padding-y / 2};
		}
		@include media-breakpoint-up(md) {
			&:nth-of-type(even) {
				.section__content-wrapper {
					padding-left: 0;
					padding-right: calc(#{$section-padding-x});
				}
			}

			&:nth-of-type(odd) {
				.section__content-wrapper {
					padding-right: 0;
					padding-left: calc(#{$section-padding-x});
				}
			}
		}
	}
}
