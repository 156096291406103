.activities-events-gallery-section {
	padding-top: 7.5rem;
	padding-bottom: 7.5rem;

	.swiper {
		&-wrapper {
			padding-bottom: 7.5rem;
			align-items: center;
			height: 22.75rem;
		}
		&-slide {
			width: fit-content !important;
			height: 80%;

			@include media-breakpoint-down(sm) {
				height: 60%;
			}

			&-active {
				height: 100%;
				transition: $transition-base;
				@include media-breakpoint-down(sm) {
					height: 75%;
				}
			}
		}
	}
}
