#news-single-page {
	.app-article {
		padding-right: $grid-gutter-width;
		padding-left: $grid-gutter-width;
	}
	.app-article {
		&-header {
			.section {
				&__title {
					line-height: $line-height-base;
				}
				&__media {
					&-image {
						min-height: 300px;
						max-height: 300px;
					}
				}
				&__content-wrapper {
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}
	}
}
