#volunteering-page {
	.app-article {
		padding-left: $grid-gutter-width;
		@include media-breakpoint-down(md) {
			padding-right: $grid-gutter-width;
		}
	}

	.section {
		&__content-wrapper {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	#volunteering-section-gallery-slider {
		.swiper {
			&-wrapper {
				height: 295px;
			}
		}
	}
}
