#school-index-page {
	.app-main-header {
		padding-top: calc((#{$header-max-height} * 1.75) + #{$app-main-header-padding-y * 2});
		padding-bottom: calc(#{$app-main-header-padding-y * 2});
	}

	.section {
		&__content-wrapper {
			padding-right: #{$section-padding-x * 0.5};
			padding-left: #{$section-padding-x * 0.5};
		}
	}
}
