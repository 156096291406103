.logo {
	&__container {
		height: 100%;
		display: flex;
		align-items: center;
		&,
		&:hover {
			color: inherit;
			transition: $transition-base;
		}
	}
	&__image {
		height: 100%;
		height: 2.5rem;
		width: 2.5rem;
		object-fit: scale-down;
		object-position: center;
		margin-right: #{$grid-gutter-width * 0.5};
	}

	&__name-container {
		width: fit-content;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;

		&--horizontal {
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			.app-header__logo-school-name,
			.app-header__logo-branch-name {
				&:not(:last-child) {
					margin-right: 0.2rem;
				}
			}
		}
	}
	&__school-name,
	&__branch-name {
		display: block;
		margin: 0;
		white-space: nowrap;
		text-transform: uppercase;
	}
	&__school-name {
		font-size: #{$font-size-base * 1.125};
		font-family: $font-family-cinzel #{"/*rtl:prepend:" $font-family-cairo ",*/"};
	}
	&__branch-name {
		font-size: #{$font-size-base * 0.75};
		font-weight: $font-weight-light;
	}
}
