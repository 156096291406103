.contact-us-map-section {
	padding: $contact-us-map-padding-y $contact-us-map-padding-x;
	.section {
		&__content-wrapper {
			padding: #{$section-padding-y * 0.5} #{$section-padding-x * 0.75};
		}
		&__content {
			dt {
				font-weight: $dd-font-weight;
			}
			dd {
				font-weight: $dt-font-weight;
			}
		}
	}
}
