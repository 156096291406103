.vacancy {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	height: $vacancy-height;
	word-wrap: break-word;
	background-color: $vacancy-bg;
	background-clip: border-box;
	border: $vacancy-border-width $border-style $vacancy-border-color;
	margin-bottom: $grid-gutter-width;
	@include border-radius($vacancy-border-radius);
	@include box-shadow($box-shadow-lg);

	@include media-breakpoint-down(sm) {
		@include box-shadow($box-shadow);
	}

	&__header {
		position: relative;
		padding: $vacancy-spacer-y $vacancy-spacer-x;
		margin-bottom: 0; // Removes the default margin-bottom of <hN>
		cursor: pointer;
		text-align: left;
		vertical-align: middle;
		user-select: none;
		background-color: transparent;
		border: 0;
		border-bottom: $vacancy-border-width $border-style $vacancy-border-color;

		&:first-child {
			padding-bottom: 0;
		}

		&::after,
		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			color: $vacancy-collapse-icon-color;
			height: $vacancy-collapse-icon-bg-height;
			width: $vacancy-collapse-icon-bg-width;
		}
		&::after {
			z-index: 0;
			background-color: $vacancy-collapse-icon-bg-color;
		}
		&::before {
			z-index: 1;
			background-image: $vacancy-collapse-icon-bg-closed;
			background-size: $vacancy-collapse-icon-bg-width $vacancy-collapse-icon-bg-height;
			background-repeat: no-repeat;
			background-position: center;
			transition: $transition-base;
			transform: rotate(90deg);
		}

		&[aria-expanded="true"],
		&:not(.collapsed) {
			&::before {
				background-image: $vacancy-collapse-icon-bg-opened;
				transform: rotate(0);
			}
		}
	}

	&__body {
		// Enable `flex-grow: 1` for decks and groups so that card blocks take up
		// as much space as possible, ensuring footers are aligned to the bottom.
		flex: 1 1 auto;
		min-height: 1px;
		padding: $vacancy-spacer-x;
		color: $vacancy-color;
		transition: $transition-base;
		&:first-child {
			padding-bottom: 0;
		}
		&:last-child {
			padding-top: 0;
		}
	}
	&__footer {
		padding: $vacancy-spacer-y $vacancy-spacer-x;
		border: 0;
		border-top: $vacancy-border-width $border-style $vacancy-border-color;
		&:last-child {
			padding-top: 0;
		}
	}

	&__title {
		font-weight: $font-weight-light;
		color: $vacancy-title-color;
		margin-bottom: 0;
	}
	&__time {
		font-weight: $font-weight-light;
		font-size: $font-size-sm;
		margin-bottom: map-get($spacers, 2);
	}

	&__content {
		@extend .section__content;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: $headings-font-weight !important;
		}
	}
}
