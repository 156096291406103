.award {
	padding: $award-padding;
	background-color: $body-bg;
	color: $body-color;
	display: flex;
	height: 100%;

	&,
	&.award--with-hover:hover {
		transition: $transition-base !important;
	}

	&--shadow-sm {
		@include box-shadow($box-shadow-sm);
		&.award--with-hover {
			&:hover {
				@include box-shadow($box-shadow);
			}
		}
	}

	&--shadow-md {
		@include box-shadow($box-shadow);
		&.award--with-hover {
			&:hover {
				@include box-shadow($box-shadow-lg);
			}
		}
	}

	&--shadow-lg {
		@include box-shadow($box-shadow-lg);
	}

	&--shadow-none {
		@include box-shadow(none !important);
	}

	&--vertical {
		flex-direction: column;
		align-items: stretch;
		&,
		.award__body,
		.award__header {
			text-align: center;
		}
		.award__header {
			& + .award__body {
				padding-top: calc(#{$award-padding-y / 2});
			}
		}
	}
	&--horizontal {
		flex-direction: row;
		padding-right: 0;
		padding-left: 0;
		.award__header {
			& + .award__body {
				padding-left: calc(#{$award-padding-y / 2});
			}
		}
	}

	&__header {
	}
	&__body {
	}

	&__medal {
		height: 3.6rem;
		width: 2.6475rem;
		margin-right: auto;
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
			.fill {
				fill: $body-color;
			}
		}
	}
	&__title,
	&__text {
		font-weight: $award-font-weight;
	}
}

@each $color, $color-value in $theme-colors {
	.award--bg-#{$color} {
		@if $color == "transparent" {
			color: $body-color;
			background-color: transparent;
		} @else {
			background-color: $color-value;
			color: color-yiq($color-value);
		}

		.award__body,
		.award__header {
			@if $color == "transparent" {
				.fill {
					fill: $link-color;
				}
			} @else {
				.fill {
					fill: color-yiq($color-value, $link-color);
				}
			}
		}
	}

	.award__title--#{$color} {
		color: color-yiq($color-value, $headings-color, $white);
	}
}
