/////////////////////////////////////////////////////////
// Animation Default Settings                          //
/////////////////////////////////////////////////////////

// Base module settings
$base-duration: 0.75s !default; // used in 1-base module
$base-origin: top center !default;
$base-degrees: 30deg !default; // used in lightspeed & special modules
$base-fill-mode: both !default;
$base-iteration-count: infinite !default;

$base-timing-function-in: ease-in !default;
$base-timing-function-out: ease-out !default;
$base-timing-function-in-out: ease-in-out !default;

$base-distance: 15px !default; // used in fade, shake and bounce modules
$base-distance-big: 1000px !default; // used in fadeBig and bounce modules

// Rotate module settings
$rotate-degrees: 90deg !default;
$rotate-degrees-max: 200deg !default;
$rotate-origin: center center !default;

// Flipper module settings
$flipper-perspective: 400px !default;
$flipper-translate: 150px !default;
$flipper-degrees: 10deg !default;
$flipper-degrees-max: 90deg !default;

// Hinge module settings
$hinge-origin: top left !default;
$hinge-degrees-max: 80deg !default;
$hinge-translate: 700px !default;

//==================================================================================================//
// Module loading                                                                                   //
//==================================================================================================//
// To include an animation module in the compiled css file, simply set it's attribute below to true //
// (all modules set to false will not be included)                                                  //
// The idea here is to only load the animations you want to use on a per project basis              //
// This helps keep the compiled css file at its leanest                                             //
//==================================================================================================//
// Hugely influenced and inspired by @csswizardry's inuit.css _vars.scss file                       //
//==================================================================================================//

$use-all: true !default; // all animations status
$use-fade: $use-all !default; // all fades
$use-bounce: $use-all !default; // all bounces
$use-rotate: $use-all !default; // all rotates
$use-slide: $use-all !default; // all slide animations
$use-flipers: $use-all !default; // all flip animations
$use-light-speed: $use-all !default; // all light speed animations
$use-attention-seekers: $use-all !default; // all attention seeker animations
$use-special: $use-all !default; // all special animations
$use-zoom: $use-all !default; // all zoom animations
// Fade Enter
$use-fade-in: $use-fade !default;
$use-fadeIn: $use-fade-in !default;
$use-fadeInUp: $use-fade-in !default;
$use-fadeInUpBig: $use-fade-in !default;
$use-fadeInDown: $use-fade-in !default;
$use-fadeInDownBig: $use-fade-in !default;
$use-fadeInLeft: $use-fade-in !default;
$use-fadeInLeftBig: $use-fade-in !default;
$use-fadeInRight: $use-fade-in !default;
$use-fadeInRightBig: $use-fade-in !default;

// Fade Exit
$use-fade-out: $use-fade !default;
$use-fadeOut: $use-fade-out !default;
$use-fadeOutUp: $use-fade-out !default;
$use-fadeOutUpBig: $use-fade-out !default;
$use-fadeOutDown: $use-fade-out !default;
$use-fadeOutDownBig: $use-fade-out !default;
$use-fadeOutLeft: $use-fade-out !default;
$use-fadeOutLeftBig: $use-fade-out !default;
$use-fadeOutRight: $use-fade-out !default;
$use-fadeOutRightBig: $use-fade-out !default;

// Bounce Enter
$use-bounce-in: $use-bounce !default;
$use-bounceIn: $use-bounce-in !default;
$use-bounceInUp: $use-bounce-in !default;
$use-bounceInDown: $use-bounce-in !default;
$use-bounceInLeft: $use-bounce-in !default;
$use-bounceInRight: $use-bounce-in !default;

// Bounce Exit
$use-bounce-out: $use-bounce !default;
$use-bounceOut: $use-bounce-out !default;
$use-bounceOutUp: $use-bounce-out !default;
$use-bounceOutDown: $use-bounce-out !default;
$use-bounceOutLeft: $use-bounce-out !default;
$use-bounceOutRight: $use-bounce-out !default;

// Rotate Enter
$use-rotate-in: $use-rotate !default;
$use-rotateIn: $use-rotate-in !default;
$use-rotateInUpLeft: $use-rotate-in !default;
$use-rotateInUpRight: $use-rotate-in !default;
$use-rotateInDownLeft: $use-rotate-in !default;
$use-rotateInDownRight: $use-rotate-in !default;

// Rotate Exit
$use-rotate-out: $use-rotate !default;
$use-rotateOut: $use-rotate-out !default;
$use-rotateOutUpLeft: $use-rotate-out !default;
$use-rotateOutUpRight: $use-rotate-out !default;
$use-rotateOutDownLeft: $use-rotate-out !default;
$use-rotateOutDownRight: $use-rotate-out !default;

// Slide Enter
$use-slide-in: $use-slide !default;
$use-slideIn: $use-slide-in !default;
$use-slideInUp: $use-slide-in !default;
$use-slideInDown: $use-slide-in !default;
$use-slideInLeft: $use-slide-in !default;
$use-slideInRight: $use-slide-in !default;

// Slide Exit
$use-slide-out: $use-slide !default;
$use-slideOut: $use-slide-out !default;
$use-slideOutUp: $use-slide-out !default;
$use-slideOutDown: $use-slide-out !default;
$use-slideOutLeft: $use-slide-out !default;
$use-slideOutRight: $use-slide-out !default;

// Flippers
$use-flip: $use-flipers !default;
$use-flipInX: $use-flipers !default;
$use-flipInY: $use-flipers !default;
$use-flipOutX: $use-flipers !default;
$use-flipOutY: $use-flipers !default;

// Lightspeed
$use-lightSpeedIn: $use-light-speed !default;
$use-lightSpeedOut: $use-light-speed !default;

// Attention Seekers
$use-bounce: $use-attention-seekers !default;
$use-flash: $use-attention-seekers !default;
$use-pulse: $use-attention-seekers !default;
$use-wiggle: $use-attention-seekers !default;
$use-swing: $use-attention-seekers !default;
$use-shake: $use-attention-seekers !default;
$use-tada: $use-attention-seekers !default;
$use-wobble: $use-attention-seekers !default;

// Special
$use-hinge: $use-special !default;
$use-rollIn: $use-special !default;
$use-rollOut: $use-special !default;

// Zoom In
$use-zoom-in: $use-zoom !default;
$use-zoomIn: $use-zoom-in !default;
$use-zoomInDown: $use-zoom-in !default;
$use-zoomInLeft: $use-zoom-in !default;
$use-zoomInRight: $use-zoom-in !default;
$use-zoomInUp: $use-zoom-in !default;

// Zoom Out
$use-zoom-out: $use-zoom !default;
$use-zoomOut: $use-zoom-out !default;
$use-zoomOutDown: $use-zoom-out !default;
$use-zoomOutLeft: $use-zoom-out !default;
$use-zoomOutRight: $use-zoom-out !default;
$use-zoomOutUp: $use-zoom-out !default;
