.professional-development-section {
	color: $white;
	.section__bg--image-container {
		& + * {
			@include media-breakpoint-down(md) {
				padding-bottom: 15vh !important;
			}
			@include media-breakpoint-down(sm) {
				padding-bottom: 25vh !important;
			}
		}
	}
	.section__content-wrapper {
		background-color: transparent;
		padding-top: calc(#{map-get($spacers, 5)} + 1rem);
		padding-bottom: calc(#{map-get($spacers, 5)} + 1rem);
	}
	.section__content {
		max-height: 12.5rem;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 0.25rem;
			&:hover {
				width: 0.5rem;
			}
		}

		&::-webkit-scrollbar-track {
			background-color: darken($light, $emphasized-link-hover-darken-percentage);
			border-radius: $border-radius-lg;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: $border-radius-lg;
			background-color: rgba($gray-700, 0.5);
			cursor: pointer;
			&:hover {
				background-color: $gray-700;
			}
		}
	}
}
