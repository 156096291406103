$activity-card-bg: $primary;
$activity-card-padding-x: 1.375rem;
$activity-card-padding-y: 1.25rem;

.activity-card {
	position: relative;
	color: color-yiq($activity-card-bg);
	background-color: $activity-card-bg;
	height: 100%;
	text-transform: uppercase;
	overflow: hidden;

	&,
	&.activity-card--with-hover:hover {
		transition: $transition-base !important;
	}

	&--shadow-sm {
		@include box-shadow($box-shadow-sm);
		&.activity-card--with-hover {
			&:hover {
				@include box-shadow($box-shadow);
			}
		}
	}

	&--shadow-md {
		@include box-shadow($box-shadow);
		&.activity-card--with-hover {
			&:hover {
				@include box-shadow($box-shadow-lg);
			}
		}
	}

	&--shadow-lg {
		@include box-shadow($box-shadow-lg);
	}

	&--shadow-none {
		@include box-shadow(none !important);
	}

	&__image-container {
		display: block;
		overflow: hidden;
		height: 10rem;
		width: 100%;
	}

	&__image {
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__content {
		padding: $activity-card-padding-y $activity-card-padding-x;
	}

	&__time {
		font-weight: $font-weight-light;
		color: inherit;
		font-size: $font-size-sm;
		margin-bottom: map-get($spacers, 2);
	}

	&__title {
		font-weight: $font-weight-base;
		margin-bottom: 0;

		&,
		a,
		a:hover,
		a:focus,
		a:active {
			color: inherit;
			font-family: inherit;
		}
	}
}
