.news-showcase-section {
	padding-top: 1.125rem;
	padding-bottom: 1.125rem;
	.section {
		&__content-wrapper {
			padding-top: $section-padding-y / 2;
			padding-bottom: $section-padding-y / 2;
		}
	}
}
