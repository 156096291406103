.app-preloader {
	position: fixed;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: $body-bg;
	z-index: $zindex-preloader;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: $transition-base;
}
