@if $use-fadeOutRight == true {

	@-webkit-keyframes fadeOutRight {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateX($base-distance * 2);
		}
	}

	@keyframes fadeOutRight {
		0% {
			opacity: 1;
			transform: translateX(0);
		}

		100% {
			opacity: 0;
			transform: translateX($base-distance * 2);
		}
	}

	.fadeOutRight {
		@include animate-prefixer(animation-name, fadeOutRight);
	}
}
