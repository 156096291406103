@if $use-rollOut == true {

	// originally authored by Nick Pettit - https://github.com/nickpettit/glide

	@-webkit-keyframes rollOut {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0px) rotate(0deg);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateX(100%) rotate($base-degrees * 4);
		}
	}

	@keyframes rollOut {
		0% {
			opacity: 1;
			transform: translateX(0px) rotate(0deg);
		}

		100% {
			opacity: 0;
			transform: translateX(100%) rotate($base-degrees * 4);
		}
	}

	.rollOut {
		@include animate-prefixer(animation-name, rollOut);
	}

}
