.app-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transition: $transition-base;
	z-index: $zindex-header;

	&--has-side {
		.app-header__side {
			display: flex;
		}
		.app-header__main {
			padding-left: $side-menu-header-max-width;
		}
		.app-header__main-school {
			@include media-breakpoint-up(map-get-next($grid-breakpoints, $header-responsive-breakpoint-down, false, key)) {
				padding-left: $side-menu-header-max-width;
			}
		}
		@include media-breakpoint-up(map-get-next($grid-breakpoints, $header-responsive-breakpoint-down, false, key)) {
			& ~ .app-main,
			& ~ .app-footer {
				padding-left: calc(#{$side-menu-header-max-width} - #{$header-border-width});
			}
		}
	}

	&--scrolling {
		position: fixed;
		width: 100%;
		transition: $transition-base;

		&-main,
		&-main-school {
			.app-header__main {
				.logo__container {
					padding-top: $header-padding-y * 0.4 !important;
					padding-bottom: $header-padding-y * 0.4 !important;

					@include media-breakpoint-down($header-responsive-breakpoint-down) {
						padding-top: $header-padding-y * 0.325 !important;
						padding-bottom: $header-padding-y * 0.325 !important;
					}
				}
				.app-header__nav {
					&:not(.app-header__nav--is-open) {
						.app-header__list-item,
						.menu-item {
							a {
								padding-top: $header-padding-y * 0.75 !important;
								padding-bottom: $header-padding-y * 0.75 !important;
							}
						}
					}

					&--is-open {
						height: calc(100vh - calc(#{($header-padding-y * 0.75) * 2} + #{$font-size-sm * $line-height-base}) - 2px);
					}
				}

				.app-header__action-button--open-search,
				.app-header__action-button--close-search,
				.app-header__action-button--open-dropdown-nav {
					padding-top: $header-padding-y * 0.75 !important;
					padding-bottom: $header-padding-y * 0.75 !important;
				}
			}
			.logo {
				&__image {
					@include media-breakpoint-down(xs) {
						margin-right: 0;
					}
				}
				&__name-container {
					@include media-breakpoint-down(xs) {
						display: none;
					}
				}
			}
		}

		&-main {
			.app-header__main {
				background-color: $header-scrolling-bg-color !important;
				border-bottom-color: rgba(color-yiq($header-scrolling-bg-color, $cyan), 0.3) !important;
				.app-header__nav:not(.app-header__nav--is-open) {
					.app-header__list-item,
					.menu-item {
						&,
						& > a {
							color: color-yiq($header-scrolling-bg-color, $cyan);
						}
						a {
							&:after {
								background-color: color-yiq($header-scrolling-bg-color, $cyan) !important;
							}
						}
					}
				}
				.app-header__action-button--open-search,
				.app-header__action-button--close-search,
				.app-header__action-button--open-dropdown-nav {
					border-left-color: rgba(color-yiq($header-scrolling-bg-color, $cyan), 0.3) !important;
					color: color-yiq($header-scrolling-bg-color, $cyan);
				}
			}
		}

		&-main-school {
			.app-header__main {
				display: none;
			}
			.app-header__main-school {
				padding-left: $side-menu-header-max-width;
			}
		}

		@include media-breakpoint-down($header-responsive-breakpoint-down) {
			.app-header__side {
				.social,
				.app-header__action-button--close-menu,
				.app-header__action-button--open-menu {
					padding-top: $header-padding-y * 0.8 !important;
					padding-bottom: $header-padding-y * 0.8 !important;
					border-bottom: 0 !important;
				}
			}
		}

		&-up {
			height: 0;
			.app-header__main,
			.app-header__main-school {
				animation: slide-up 0.7s;
				animation-fill-mode: forwards;
			}

			@include media-breakpoint-down($header-responsive-breakpoint-down) {
				.app-header__side-header {
					animation: slide-up 0.7s;
					animation-fill-mode: forwards;
				}
			}
		}

		&-down {
			.app-header__main,
			.app-header__main-school {
				animation: slide-down 0.7s;
			}
			@include media-breakpoint-down($header-responsive-breakpoint-down) {
				.app-header__side-header {
					animation: slide-down 0.7s;
				}
			}
		}
	}

	&:not(.app-header--scrolling) {
		.app-header__main-school {
			display: none;
		}
	}

	&__main,
	&__main-school {
		display: flex;
		align-items: center;
		background-color: $header-bg-color;
		border-bottom: $header-border-width $header-border-style $header-border-color;
		max-height: $header-max-height;
		z-index: $zindex-header;
		padding-right: #{$grid-gutter-width / 2};
		transition: $transition-base;
		color: $header-color;
		.app-header__nav {
			&:not(.app-header__nav--is-open) {
				.app-header__list-item,
				.menu-item {
					margin-right: $header-padding-x;
				}
			}

			&--is-open {
				position: absolute;
				top: 100%;
				left: 0;
				height: calc(100vh - calc(#{$header-padding-y * 2} + #{$font-size-sm * $line-height-base}) - 2px);
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				display: block !important;
				align-items: flex-start;
				z-index: $zindex-dropdown-nav;
				-webkit-transform: translate3d(0, 0, 0);
				-moz-transform: translate3d(0, 0, 0);
				-ms-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
				overflow-x: hidden;
				overflow-y: scroll;
				-webkit-overflow-scrolling: touch; /* IOS */
				-ms-overflow-style: none; /* IE 11 */
				scrollbar-width: none; /* Firefox 64 */
				&::-webkit-scrollbar {
					width: 0;
					background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
				}

				.app-header__list {
					height: auto;
					display: block;
					flex-direction: column;
					width: 100%;
					align-items: center;
					justify-content: flex-start;
					padding-bottom: 7rem; // IOS scrolling scrolling.

					
				}

				.app-header__list-item,
				.menu-item {
					margin-bottom: $header-padding-x * 0.25;
					text-align: center;
					padding-top: 0;
					padding-bottom: 0;
					max-width: fit-content;
					width: auto;
					margin-left: auto;
					margin-right: auto;
				}
			}
			@include media-breakpoint-down($header-responsive-breakpoint-down) {
				display: none;
			}
		}

		.logo {
			&__container {
				padding: 0 $header-padding-x;
				border-right: $header-border-width $header-border-style $header-border-color;
				@include media-breakpoint-up(sm) {
					margin-right: $header-padding-x;
				}
			}
		}
	}
	&__main {
		.app-header__nav {
			justify-content: flex-end;
			&--is-open {
				background-color: $header-scrolling-bg-color;
				// left: $side-menu-header-max-width;
				// width: calc(
				// 	100vw -
				// 		(
				// 			#{$side-menu-header-max-width} + (
				// 					(#{$header-action-padding-x} * 2) + #{$header-action-buttons-width} + #{$header-border-width}
				// 				) + (#{$grid-gutter-width} * 1.5)
				// 		)
				// );
			}
		}
	}

	&__main-school {
		background-color: $primary !important;
		// border-bottom-color: rgba(color-yiq($header-scrolling-bg-color, $primary), 0.3) !important;
		border-bottom-color: transparent !important;
		@include box-shadow($box-shadow);

		.logo__container {
			padding-top: $header-padding-y * 0.425 !important;
			padding-bottom: $header-padding-y * 0.425 !important;
		}

		.app-header__nav {
			justify-content: flex-start;
			&:not(.app-header__nav--is-open) {
				.app-header__list-item,
				.menu-item {
					&,
					& > a {
						color: color-yiq($header-scrolling-bg-color, $primary);
					}
					a {
						padding-top: $header-padding-y * 0.75 !important;
						padding-bottom: $header-padding-y * 0.75 !important;
						&:after {
							background-color: color-yiq($header-scrolling-bg-color, $primary) !important;
						}
					}
				}
			}

			&--is-open {
				border-top: $header-border-width $header-border-style rgba(color-yiq($header-scrolling-bg-color, $primary), 0.3) !important;
				height: calc(100vh - calc(#{($header-padding-y * 0.75) * 2} + #{$font-size-sm * $line-height-base}) - 2px);
				background-color: $primary;
			}
		}
		.app-header__action-button--open-search,
		.app-header__action-button--close-search,
		.app-header__action-button--open-dropdown-nav {
			border-left-color: rgba(color-yiq($header-scrolling-bg-color, $primary), 0.3) !important;
			color: color-yiq($header-scrolling-bg-color, $primary);
			padding-top: $header-padding-y * 0.75 !important;
			padding-bottom: $header-padding-y * 0.75 !important;
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding-right: #{$grid-gutter-width / 2};
		padding-left: #{$grid-gutter-width / 2};
	}

	&__list {
		height: 100%;
		margin: 0; // for ul element margins.
		display: flex;
	}

	&__list-item,
	.menu-item {
		list-style: none;
		position: relative;
		font-size: $font-size-sm;
		font-weight: $font-weight-base;
		text-transform: uppercase;
		color: $header-color;

		a {
			padding-top: $header-padding-y;
			padding-bottom: $header-padding-y;
			position: relative;
			width: 100%;
			display: block;
			color: inherit;
			&:after {
				content: "";
				transition: $transition-base;
				position: absolute;
				bottom: calc(#{$header-border-width} * 3);
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
				height: 2px;
				background-color: $header-color;
				opacity: 0;
			}
		}

		&--active,
		&.active,
		&:hover,
		&:focus,
		&:active {
			&,
			a,
			a:focus,
			a:active,
			a:hover {
				transition: $transition-base;
				&:after {
					opacity: 1;
					bottom: calc(-1 * #{$header-border-width * 2});
					transition: $transition-base;
				}
			}
		}
	}

	&__side {
		background-color: $side-menu-bg-color;
		color: $side-menu-color;
		position: fixed;
		top: 0;
		min-height: 100vh;
		width: 100%;
		display: none;
		z-index: $zindex-overlay-menu;
		-ms-overflow-style: none; /* IE 11 */
		scrollbar-width: none; /* Firefox 64 */
		-webkit-overflow-scrolling: touch; /* IOS */

		&::-webkit-scrollbar {
			width: 0;
			background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
		}

		&:not(.app-header__side--is-open):not(.app-header__side--is-opening) {
			.app-header__side-header {
				border-color: transparent;
				.app-header__action-button {
					&--open-menu,
					&--close-menu {
						border-bottom: $header-border-width $header-border-style $header-border-color;
					}
				}
			}
		}

		&:not(.app-header__side--is-open) {
			@include media-breakpoint-up(map-get-next($grid-breakpoints, $header-responsive-breakpoint-down, false, key)) {
				left: calc(-100% + #{$side-menu-header-max-width});
			}
			@include media-breakpoint-down($header-responsive-breakpoint-down) {
				left: -100%;
				.app-header__action-button--open-menu,
				.app-header__action-button--close-menu {
					margin-right: calc(-1 * #{$side-menu-header-max-width} + #{$header-border-width});
				}
			}
		}

		&.app-header__side--is-open,
		&.app-header__side--is-opening {
			z-index: $zindex-overlay-menu;

			.app-header__side-content {
				width: calc(100%);
			}
		}

		&.app-header__side--is-open {
			overflow-y: scroll;
			left: 0;

			/* scroll bar width */
			&::-webkit-scrollbar {
				width: 0;
			}

			.app-header__side-header {
				border-bottom: $header-border-width $header-border-style $header-border-color;
			}
			.app-header__action-button {
				&--open-menu,
				&--close-menu {
					@include media-breakpoint-down($header-responsive-breakpoint-down) {
						border-left: $header-border-width $header-border-style $header-border-color;
					}
				}
			}
		}

		&.sideInLeft {
			@include animate-prefixer(animation-name, sideInLeft);
		}
		&.sideOutLeft {
			@include animate-prefixer(animation-name, sideOutLeft);
		}

		&-header {
			width: 100%;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: space-between;
			border-right: 0;
			background-color: $side-menu-bg-color;
			transition: $transition-base;
			@include media-breakpoint-down(xs) {
				flex-wrap: wrap;
			}

			&-logo {
				font-size: #{$font-size-base * 1.375};
				font-family: $font-family-cinzel;
				font-weight: $font-weight-base;
				&,
				&:hover {
					color: $side-menu-color;
				}
			}

			&-logo-container {
				display: flex;
				align-items: center;
				justify-content: center;
				height: fit-content;
				width: 100%;
				height: 100%;

				@include media-breakpoint-down(xs) {
					flex: 2 0 100%;
					order: 3;
					height: fit-content;
					padding-top: $header-padding-y / 2;
					padding-bottom: $header-padding-y / 2;
					border-top: $header-border-width $header-border-style $header-border-color;
				}
			}

			.social {
				padding: calc(#{$header-padding-y} - #{$header-border-width}) $header-action-padding-x;
				border-right: $header-border-width $header-border-style $header-border-color;
				&__list {
					flex-direction: row;
				}
				&__list-item,
				&__link {
					color: $side-menu-color;
				}
				&__list-item {
					&:not(:last-child) {
						margin-right: 1.5rem;
					}
				}
				&__link {
					opacity: 0.5;
					&,
					&:hover,
					&:focus,
					&:active {
						transition: $transition-base;
					}
					&:hover,
					&:focus,
					&:active {
						opacity: 1;
					}
				}

				@include media-breakpoint-down(xs) {
					order: 2;
					flex: auto;
					border-right: 0;
				}
			}

			.app-header__action-button {
				&--open-menu,
				&--close-menu {
					color: $side-menu-color;
					@include media-breakpoint-up(map-get-next($grid-breakpoints, $header-responsive-breakpoint-down, false, key)) {
						border-bottom: $header-border-width $header-border-style $header-border-color;
					}
				}
			}

			@include media-breakpoint-up(map-get-next($grid-breakpoints, $header-responsive-breakpoint-down, false, key)) {
				order: 2;
				flex-direction: column;
				width: $side-menu-header-max-width;
				border-left: $header-border-width $header-border-style $header-border-color;
				border-bottom: 0;
				&-logo {
					writing-mode: vertical-lr;
					transform: rotate(-180deg);
					filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); //For IE support
				}
				.social {
					border-right: 0;
					&__list {
						flex-direction: column;
						.social__list-item {
							&:not(:last-child) {
								margin-right: 0;
								margin-bottom: 1.5rem;
							}
						}
					}
				}
			}
		}

		&-content {
			display: block;
			position: relative;
			width: calc(100% - #{$side-menu-header-max-width});
			max-height: 100vh;
			height: 100vh;
			transition: $transition-base;

			overflow-x: hidden;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch; /* IOS */
			scrollbar-width: none; /* Firefox 64 */
			-ms-overflow-style: none; /* IE 11 */
			&::-webkit-scrollbar {
				width: 0;
				background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
			}

			@include media-breakpoint-up(map-get-next($grid-breakpoints, $header-responsive-breakpoint-down, false, key)) {
				width: calc(100% - #{$side-menu-header-max-width});
				order: 1;
			}
		}

		@include media-breakpoint-down($header-responsive-breakpoint-down) {
			flex-direction: column;
		}
	}

	&__campuses {
		&-list {
			height: 100%;
			padding: $side-menu-campuses-list-padding-y $side-menu-campuses-list-padding-x;

			overflow-x: hidden;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch; /* IOS */
			-ms-overflow-style: none; /* IE 11 */
			scrollbar-width: none; /* Firefox 64 */
			&::-webkit-scrollbar {
				width: 0;
				background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
			}

			@include media-breakpoint-down($header-responsive-breakpoint-down) {
				padding: #{$side-menu-campuses-list-padding-y * 0.25} #{$side-menu-campuses-list-padding-x * 0.25};
				padding-bottom: #{$side-menu-campuses-list-padding-y * 2}; // For Safari browser.
			}
		}

		&-content {
		}

		&-section {
			position: absolute;
			display: flex;
			top: 0;
			width: 100%;
			height: calc(100vh + 200px);
			padding: $side-menu-campus-header-padding-y $side-menu-campus-header-padding-x;
			background-color: $side-menu-bg-color;
			color: $side-menu-color;
			transition: $transition-base;
			overflow-x: hidden;
			overflow-y: scroll;
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			@include box-shadow($box-shadow);

			-ms-overflow-style: none; /* IE 11 */
			scrollbar-width: none; /* Firefox 64 */
			&::-webkit-scrollbar {
				width: 0;
				background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
			}

			&:not(.app-header__campuses-section--is-open) {
				right: -100%;
				display: none;
			}

			&.sideInRight {
				@include animate-prefixer(animation-name, sideInRight);
			}
			&.sideOutRight {
				@include animate-prefixer(animation-name, sideOutRight);
			}
		}

		&-section-header {
			.btn {
				color: $side-menu-color;
				margin-right: 1rem;
				&-icon {
					svg {
						width: 2em;
						height: 2em;
					}
				}
			}

			.breadcrumb {
				justify-content: flex-start;
				&-item {
					+ .breadcrumb-item {
						&::before {
							content: escape-svg("/");
						}
					}
				}
			}
		}

		&-section-title {
			color: $side-menu-color;
			display: flex;
			align-items: center;

			.logo {
				&__image {
					width: auto;
				}
				&__name-container {
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
				}

				&__school-name,
				&__branch-name {
					font-family: $font-family-cinzel #{"/*rtl:prepend:" $font-family-cairo ",*/"};
					font-size: $font-size-base;
					font-weight: $font-weight-base;
					position: relative;

					&:not(:last-child) {
						padding-right: 0.5rem;
						&:after {
							content: "-";
							color: inherit;
							font-size: inherit;
							display: inline-block; // Suppress underlining of the separator in modern browsers
							padding-left: 0.5rem;
						}
					}
				}
			}
		}

		&-section-nav {
			margin-top: map-get($spacers, 5);
			.app-header__list {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;

				&-item {
					&,
					a {
						text-transform: initial;
					}
					a {
						padding-top: #{$header-padding-y * 0.5};
						padding-bottom: #{$header-padding-y * 0.5};
						&::after {
							content: none;
						}
					}
				}
			}
		}
	}

	&__search {
		position: absolute;
		top: calc(100% + #{$grid-gutter-width / 2});
		right: calc(#{$grid-gutter-width / 2});

		&:not(.app-header__search--is-open) {
			display: none;
		}

		&-input-group {
			display: flex;
			align-items: center;
			position: relative;
			background-color: $search-input-bg-color;
			color: $search-input-color;
			.fill {
				fill: $search-input-color;
			}
		}

		&-input {
			background-color: inherit;
			border: $search-input-border-width $search-input-border-style $search-input-border-color;
			padding: 1.5rem 1.125rem;
			padding-right: calc(#{$search-input-padding-x * 2} + #{$header-action-buttons-width});
			color: inherit;
			outline-offset: calc(-#{$search-input-border-width} * 2);

			&[type="search"] + .app-header__search-submit {
				border-left: 0;
			}

			&::placeholder {
				color: inherit;
				opacity: 0.5;
				text-transform: capitalize;
			}

			&[type="search"] + .app-header__search-submit {
				visibility: hidden;
			}

			&:placeholder-shown {
				&[type="search"] + .app-header__search-submit {
					visibility: visible;
				}
			}

			&::-webkit-search-cancel-button {
				-webkit-appearance: none;
				position: absolute;
				padding: $search-input-padding-y $search-input-padding-x;
				border: 0;
				right: calc((-#{$search-input-padding-x * 2} + #{$header-action-buttons-width}) + (#{$search-input-border-width} * 4));
				top: 50%;
				transform: translateY(-50%);
				background-image: str-replace(
					url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='18.473' height='18.473' viewBox='0 0 18.473 18.473'><g transform='translate(-3.263 -95.201)'><g transform='translate(3.263 112.879) rotate(-45)'><g transform='translate(0 0)'><path style='fill:#{$search-input-color};' d='M24.479,245.333H.521a.564.564,0,0,0,0,1.125H24.479a.564.564,0,0,0,0-1.125Z' transform='translate(0 -245.333)'></path></g></g><g transform='translate(4.059 95.201) rotate(45)'><g transform='translate(0 0)'><path style='fill:#{$header-color};' d='M24.479,245.333H.521a.564.564,0,0,0,0,1.125H24.479a.564.564,0,0,0,0-1.125Z' transform='translate(0 -245.333)'></path></g></g></g></svg>"),
					"#",
					"%23"
				);
				background-size: $header-action-buttons-width * 0.5 $header-action-buttons-height;
				background-position: center;
				background-repeat: no-repeat;
				width: $header-action-buttons-width;
				cursor: pointer;
				@if $enable-outline {
					height: calc(100% - (#{$search-input-border-width} * 6));
				} @else {
					height: calc(100% - (#{$search-input-border-width} * 4));
				}
			}
		}

		&-submit {
			padding: $search-input-padding-y $search-input-padding-x;
			border: 0;
			background-color: transparent;
			position: absolute;
			right: calc(#{$search-input-border-width} * 4);
			top: 50%;
			transform: translateY(-50%);
			width: auto;
			@if $enable-outline {
				height: calc(100% - (#{$search-input-border-width} * 6));
			} @else {
				height: calc(100% - (#{$search-input-border-width} * 4));
			}

			&,
			&-icon-container {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: inherit;
			}

			&-icon-container {
				border-left: 0;
				width: $header-action-buttons-width;
				height: $header-action-buttons-height;
				max-width: 100%;
				max-height: 100%;

				svg,
				img,
				.app-header__search-submit-icon {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}

	&__action-button {
		padding: $header-padding-y $header-action-padding-x;
		color: $header-color;
		border-right: 0;
		border-top: 0;
		border-bottom: 0;
		border-left: 0;
		transition: $transition-base;
		background-color: $header-bg-color;

		&--open-search {
			border-left: $header-border-width $header-border-style $header-border-color;
		}
		&--close-search {
			border-left: $header-border-width $header-border-style $header-border-color;
		}
		&--open-dropdown-nav {
			width: 100%;
			justify-content: flex-end !important;
			@include media-breakpoint-up(map-get-next($grid-breakpoints, $header-responsive-breakpoint-down, false, key)) {
				display: none !important;
			}
		}
		&--open-menu,
		&--close-menu {
			background-color: inherit !important;
		}

		&:focus,
		&:active {
			.app-header__action-button-container {
				transform: scale(0.95);
			}
		}

		&,
		&-container {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: $transition-base;
		}

		&-container {
			width: $header-action-buttons-width;
			height: $header-action-buttons-height;
			max-width: 100%;
			max-height: 100%;

			svg,
			img,
			.app-header__action-button-icon {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&-text {
			font-size: $font-size-sm;
			font-weight: $font-weight-base;
			text-transform: uppercase;
			margin: 0;
		}
	}

	&__underlay {
		position: fixed;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100%;
		z-index: $zindex-overlay-menu-backdrop;
		display: none;
		transition: $transition-base;

		&--is-open {
			display: block !important;
			cursor: pointer;
			backdrop-filter: blur(3px);
			background-color: rgba($black, 0.85);
		}
	}

	@media screen and (max-height: 700px) {
		.app-header {
			&__side {
				height: 100%;
				&-content {
					height: 100%;
				}
			}
		}
	}
}

@each $color, $color-value in $theme-colors {
	.app-header__campuses-section--color-#{$color} {
		hr {
			border-top-color: $color-value;
		}
	}
}

// Slide animation
@keyframes sideInRight {
	0% {
		right: calc(-100%);
	}
	100% {
		right: 0;
	}
}
@keyframes sideOutRight {
	0% {
		right: 0;
	}
	100% {
		right: calc(-100%);
	}
}

@keyframes sideInLeft {
	0% {
		left: calc(-100% + #{$side-menu-header-max-width});
	}
	100% {
		left: 0;
	}
}
@keyframes sideOutLeft {
	0% {
		left: 0;
	}
	100% {
		left: calc(-100% + #{$side-menu-header-max-width});
	}
}

@keyframes slide-down {
	from {
		transform: translateY(calc(-1 * (100% + #{$header-border-width})));
	}
	to {
		transform: translateY(0%);
	}
}

@keyframes slide-up {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(calc(-1 * (100% + #{$header-border-width})));
	}
}
