.contact-us-section {
	padding-top: 4.5rem;
	padding-bottom: 4.5rem;
	text-align: center;

	&__title {
		@extend .h6;
		font-weight: $font-weight-light;
		margin-bottom: 0;
	}
	&__call-to-action {
		@extend .btn;
		position: relative;
		text-transform: uppercase;
		font-size: $h4-font-size;
		font-weight: $font-weight-light;
		padding-top: 0;
		padding-bottom: 0;

		&:after {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 45%;
			height: $border-width;
			background-color: $primary;
			transition: $transition-base;
		}

		&:hover {
			&:after {
				width: calc(100% - (#{$btn-padding-x} * 2));
				transition: $transition-base;
			}
		}
	}
}
