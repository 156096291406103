@if $use-fadeInRight == true {

	@-webkit-keyframes fadeInRight {
		0% {
			opacity: 0;
			-webkit-transform: translateX($base-distance * 2);
		}

		100% {
			opacity: 1;
			-webkit-transform: translateX(0);
		}
	}

	@keyframes fadeInRight {
		0% {
			opacity: 0;
			transform: translateX($base-distance * 2);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.fadeInRight {
		@include animate-prefixer(animation-name, fadeInRight);
	}
}
