// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #fafafa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #d6d6d6 !default;
$gray-500: #c4c4c4 !default;
$gray-600: #868686 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #232323 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
	(
		"100": $gray-100,
		"200": $gray-200,
		"300": $gray-300,
		"400": $gray-400,
		"500": $gray-500,
		"600": $gray-600,
		"700": $gray-700,
		"800": $gray-800,
		"900": $gray-900,
	),
	$grays
);

$blue: #143396 !default;
$blue-dark: darken(saturate($blue, 9.65), 20.39) !default;
$indigo: #197598 !default;
$indigo-dark: darken(saturate($indigo, 9.65), 20.39) !default;
$purple: #6f42c1 !default;
$purple-dark: darken(saturate($purple, 9.65), 20.39) !default;
$pink: #e83e8c !default;
$pink-dark: darken(saturate($pink, 9.65), 20.39) !default;
$red: #ff0000 !default;
$red-dark: darken(saturate($red, 9.65), 20.39) !default;
$orange: #e08333 !default;
$orange-dark: darken(saturate($orange, 9.65), 20.39) !default;
$yellow: #ffc107 !default;
$yellow-dark: darken(saturate($yellow, 9.65), 20.39) !default;
$green: #5ab075 !default;
$green-dark: darken(saturate($green, 9.65), 20.39) !default;
$teal: #20c997 !default;
$teal-dark: darken(saturate($teal, 9.65), 20.39) !default;
$cyan: #2591ba !default;
$cyan-dark: darken(saturate($cyan, 9.65), 20.39) !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
	(
		"blue": $blue,
		"blue-dark": $blue-dark,
		"indigo": $indigo,
		"indigo-dark": $indigo-dark,
		"purple": $purple,
		"purple-dark": $purple-dark,
		"pink": $pink,
		"pink-dark": $pink-dark,
		"red": $red,
		"red-dark": $red-dark,
		"orange": $orange,
		"orange-dark": $orange-dark,
		"yellow": $yellow,
		"yellow-dark": $yellow-dark,
		"green": $green,
		"green-dark": $green-dark,
		"teal": $teal,
		"teal-dark": $teal-dark,
		"cyan": $cyan,
		"cyan-dark": $cyan-dark,
		"white": $white,
		"transparent": transparent,
		"gray": $gray-600,
		"gray-dark": $gray-800,
		"black": $black,
	),
	$grays
);

$primary: $cyan !default;
$primary-dark: darken(saturate($primary, 9.65), 20.39) !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$theme-colors: ($colors) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
	(
		"primary": $primary,
		"primary-dark": $primary-dark,
		"secondary": $secondary,
		"success": $success,
		"info": $info,
		"warning": $warning,
		"danger": $danger,
		"light": $light,
		"dark": $dark,
	),
	$colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $dark !default;
$yiq-text-light: $white !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29")) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-outline: false !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: true !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;
$enable-debug: false !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
	xs: 0,
	sm: 656px,
	md: 848px,
	lg: 1192px,
	xl: 1280px,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	sm: 596px,
	md: 788px,
	lg: 1132px,
	xl: 1220px,
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 16px !default;
$grid-row-columns: 12 !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
	(
		0: 0,
		1: (
			$spacer * 0.25,
		),
		2: (
			$spacer * 0.5,
		),
		3: $spacer,
		4: (
			$spacer * 1.5,
		),
		5: (
			$spacer * 3,
		),
		gutter: $grid-gutter-width,
		section: $grid-gutter-width * 4.5,
	),
	$spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
	(
		25: 25%,
		50: 50%,
		75: 75%,
		100: 100%,
		auto: auto,
	),
	$sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $dark !default;

// Links
//
// Style anchor elements.

$link-color: theme-color("primary") !default;
$emphasized-link-hover-darken-percentage: 15% !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, $emphasized-link-hover-darken-percentage) !default;
$link-hover-decoration: none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: $emphasized-link-hover-darken-percentage !default;

// Paragraphs
//
// Style p element.
$paragraph-margin-bottom: 1.25rem !default;

$gradient-angles: (
	"0": 0deg,
	"45": 45deg,
	"90": 90deg,
	"135": 135deg,
	"180": 180deg,
	"225": 225deg,
	"270": 270deg,
	"315": 315deg,
	"360": 360deg,
) !default;

$fade-opacity: (
	"0": 0,
	"01": 0.1,
	"02": 0.2,
	"03": 0.3,
	"04": 0.4,
	"05": 0.5,
	"06": 0.6,
	"07": 0.7,
	"08": 0.8,
	"09": 0.9,
	"1": 1,
) !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1 !default;

$border-width: 1px !default;
$border-color: $gray-400 !default;
$border-style: solid !default;

$border-radius: 0.5rem !default;
$border-radius-lg: 0.75rem !default;
$border-radius-sm: 0.25rem !default;

$rounded-pill: 50rem !default;

$box-shadow-sm: 0 1px 2px rgba($black, 0.15), 0 2px 4px rgba($black, 0.15) !default;
$box-shadow: 0 1px 2px rgba($black, 0.11), 0 2px 4px rgba($black, 0.11), 0 4px 8px rgba($black, 0.11), 0 8px 16px rgba($black, 0.11) !default;
$box-shadow-lg: 0 1px 2px rgba($black, 0.08), 0 2px 4px rgba($black, 0.08), 0 4px 8px rgba($black, 0.08), 0 8px 16px rgba($black, 0.08),
	0 16px 32px rgba($black, 0.08), 0 32px 64px rgba($black, 0.08) !default;

$component-active-color: $white !default;
$component-active-bg: theme-color("cyan") !default;

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base-time: 0.25s !default;
$transition-base-function: ease-in-out !default;
$transition-base: all $transition-base-time $transition-base-function !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

$embed-responsive-aspect-ratios: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(((21 9), (16 9), (4 3), (1 1)), $embed-responsive-aspect-ratios);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
	"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-montserrat: "Montserrat", $font-family-sans-serif !default;
$font-family-cinzel: "Cinzel", $font-family-montserrat !default;
$font-family-cairo: "Cairo", $font-family-montserrat !default;
$font-family-rage: "Rage", $font-family-cinzel !default;
$font-family-base: $font-family-montserrat !default;
// stylelint-enable value-keyword-case

$font-size-base: 0.9375rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default; // 18px
$font-size-sm: $font-size-base * 0.875 !default; // 14px

$font-weight-lighter: 300 !default;
$font-weight-light: 400 !default;
$font-weight-normal: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 900 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.25 !default;

$h1-font-size: $font-size-base * 4.75 !default; // 76px
$h2-font-size: $font-size-base * 4.125 !default; // 66px
$h3-font-size: $font-size-base * 3.5 !default; // 56px
$h4-font-size: $font-size-base * 2.25 !default; // 36px
$h5-font-size: $font-size-base * 1.625 !default; // 26px
$h6-font-size: $font-size-base * 1.25 !default; // 20px

$headings-margin-bottom: $spacer !default;
$headings-font-family: $font-family-cinzel !default;
$headings-font-weight: $font-weight-base !default;
$headings-line-height: $line-height-base !default;
$headings-color: theme-color("primary-dark") !default;

$display1-size: 6rem !default; // 96px
$display2-size: 5.5rem !default; // 88px
$display3-size: 4.5rem !default; // 72px
$display4-size: 3.5rem !default; // 56px

$display1-weight: $font-weight-light !default;
$display2-weight: $font-weight-light !default;
$display3-weight: $font-weight-light !default;
$display4-weight: $font-weight-light !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: $font-weight-light !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-small-font-size: $small-font-size !default;
$blockquote-font-size: $font-size-base * 1.25 !default;

$hr-border-color: rgba($border-color, 1) !default;
$hr-border-width: $border-width !default;
$hr-margin-y: $spacer !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-light !default;
$dd-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: $spacer !default;

$mark-bg: #fcf8e3 !default;

$legend-font-size: 1.5rem;
$legend-font-weight: $font-weight-light;
$legend-margin-bottom: map-get($spacers, 4);

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-color: $body-color !default;
$table-bg: null !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-color: $table-color !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-color: $white !default;
$table-dark-bg: $gray-800 !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-color: $table-dark-color !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;

$table-striped-order: odd !default;

$table-caption-color: $text-muted !default;

$table-bg-level: -9 !default;
$table-border-level: -6 !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.625rem !default;
$input-btn-padding-x: 0.5rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-sm !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.1rem !default;
$input-btn-focus-color: rgba($component-active-bg, 0.1) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.25rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 1.5625rem !default;
$input-btn-padding-x-lg: 3.25rem !default;
$input-btn-font-size-lg: $font-size-lg !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-light !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: $input-btn-focus-box-shadow !default;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0 !default;
$btn-border-radius-lg: 0 !default;
$btn-border-radius-sm: 0 !default;

$btn-transition: $transition-base !default;
$btn-text-transform: uppercase !default;
// Forms

$label-margin-bottom: 0.5rem !default;
$label-font-weight: $font-weight-light !default;
$label-color: $body-color !default;
$label-font-size: $font-size-base !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-light !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;

$input-color: $gray-700 !default;
$input-border-color: $gray-500 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: inset 0 0 0 rgba($black, 0.075) !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: lighten($component-active-bg, 25%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-500 !default;
$input-plaintext-color: $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y) !default;
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y / 2) !default;

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm: add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg: add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$form-text-margin: map-get($spacers, 2) !default;
$form-text-transform: capitalize !default;
$form-text-font-weight: $font-weight-light !default;
$form-text-color: $text-muted !default;
$form-text-font-size: $font-size-sm !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.3rem !default;
$form-check-input-margin-x: 0.25rem !default;

$form-check-inline-margin-x: 0.75rem !default;
$form-check-inline-input-margin-x: 0.3125rem !default;

$form-grid-gutter-width: $grid-gutter-width !default; // 16px
$form-group-margin-bottom: map-get($spacers, 4) !default; // 1.5rem

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-forms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$custom-control-gutter: 0.5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-cursor: null !default;

$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-bg: $input-bg !default;

$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: $input-box-shadow !default;
$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-indicator-border-width: $input-border-width !default;

$custom-control-label-color: null !default;

$custom-control-indicator-disabled-bg: $input-disabled-bg !default;
$custom-control-label-disabled-color: $gray-600 !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg: rgba(theme-color("primary"), 0.5) !default;
$custom-control-indicator-checked-box-shadow: none !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow !default;
$custom-control-indicator-focus-border-color: $input-focus-border-color !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow: none !default;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg !default;

$custom-checkbox-indicator-border-radius: $border-radius-sm !default;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;

$custom-switch-width: $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius: $custom-control-indicator-size / 2 !default;
$custom-switch-indicator-size: subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4) !default;

$custom-select-padding-y: $input-padding-y !default;
$custom-select-padding-x: $input-padding-x !default;
$custom-select-font-family: $input-font-family !default;
$custom-select-font-size: $input-font-size !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight !default;
$custom-select-line-height: $input-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $input-bg !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 16px 16px !default; // In pixels because image dimensions
$custom-select-indicator-color: $input-color !default;
$custom-select-indicator: url("data:image/svg+xml,<svg width='1em' height='1em' viewBox='0 0 16 16' fill='#{$custom-select-indicator-color}' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z'/></svg>") !default;
$custom-select-background: escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: add(
	1em * 0.75,
	(2 * $custom-select-padding-y * 0.75) + ($custom-select-padding-x * 2) + $custom-select-indicator-padding
) !default;
$custom-select-feedback-icon-position: center right (($custom-select-padding-x * 2) + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half !default;

$custom-select-border-width: $input-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, 0.075) !default;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-width: $input-focus-width !default;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width $input-btn-focus-color !default;

$custom-select-padding-y-sm: $input-padding-y-sm !default;
$custom-select-padding-x-sm: $input-padding-x-sm !default;
$custom-select-font-size-sm: $input-font-size-sm !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-select-padding-y-lg: $input-padding-y-lg !default;
$custom-select-padding-x-lg: $input-padding-x-lg !default;
$custom-select-font-size-lg: $input-font-size-lg !default;
$custom-select-height-lg: $input-height-lg !default;

$custom-range-track-width: 100% !default;
$custom-range-track-height: 0.5rem !default;
$custom-range-track-cursor: pointer !default;
$custom-range-track-bg: $gray-300 !default;
$custom-range-track-border-radius: 1rem !default;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;

$custom-range-thumb-width: 1rem !default;
$custom-range-thumb-height: $custom-range-thumb-width !default;
$custom-range-thumb-bg: $component-active-bg !default;
$custom-range-thumb-border: 0 !default;
$custom-range-thumb-border-radius: 1rem !default;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%) !default;
$custom-range-thumb-disabled-bg: $gray-500 !default;

$custom-file-height: $input-height !default;
$custom-file-height-inner: $input-height-inner !default;
$custom-file-focus-border-color: $input-focus-border-color !default;
$custom-file-focus-box-shadow: $input-focus-box-shadow !default;
$custom-file-disabled-bg: $input-disabled-bg !default;

$custom-file-padding-y: $input-padding-y !default;
$custom-file-padding-x: $input-padding-x !default;
$custom-file-line-height: $input-line-height !default;
$custom-file-font-family: $input-font-family !default;
$custom-file-font-weight: $input-font-weight !default;
$custom-file-color: $component-active-bg !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-border-width !default;
$custom-file-border-color: transparent !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-enable: false !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (
	en: "Browse",
	ar: "تصفح الملفات",
) !default;

// Form validation

$form-feedback-margin-top: $form-text-margin !default;
$form-feedback-font-size: $small-font-size !default;
$form-feedback-valid-color: theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;

$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg width='1em' height='1em' viewBox='0 0 16 16' fill='#{$form-feedback-icon-valid-color}' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path fill-rule='evenodd' d='M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z'/></svg>") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg width='1em' height='1em' viewBox='0 0 16 16' fill='#{$form-feedback-icon-invalid-color}' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z'/></svg>") !default;

$form-validation-states: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
	(
		"valid": (
			"color": $form-feedback-valid-color,
			"icon": $form-feedback-icon-valid,
		),
		"invalid": (
			"color": $form-feedback-invalid-color,
			"icon": $form-feedback-icon-invalid,
		),
	),
	$form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-sections: 1040 !default;
$zindex-header: 1050 !default;
$zindex-overlay-open: 1060 !default;
$zindex-overlay-menu-backdrop: 1070 !default;
$zindex-dropdown-nav: 1080 !default;
$zindex-overlay-menu: 1090 !default;
$zindex-overlay-close: 1100 !default;
$zindex-preloader: 1110 !default;
$zindex-modal-backdrop: 1120 !default;
$zindex-modal: 1130 !default;
$zindex-popover: 1140 !default;
$zindex-tooltip: 1150 !default;
// Navs

$nav-link-margin-x: 2.875rem !default;
$nav-link-padding-y: 0.875rem !default;
$nav-link-padding-x: 0 !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-link-active-color: $primary !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: transparent transparent $nav-tabs-link-active-color transparent !default;
$nav-tabs-border-color: transparent !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: transparent transparent $nav-tabs-link-active-color transparent !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

$nav-divider-color: $gray-200 !default;
$nav-divider-margin-y: $spacer / 2 !default;

$nav-bg: inherit !default;
$nav-font-weight: $font-weight-bold !default;
$nav-font-size: $font-size-sm !default;
$nav-text-transform: uppercase !default;
$nav-border-width: $border-width !default;
$nav-border-color: $gray-300 !default;

$nav-hover-color: $link-color !default;
$nav-hover-bg: $nav-bg !default;
$nav-hover-border-color: $nav-border-color !default;

$nav-active-color: $nav-hover-color !default;
$nav-active-bg: $nav-bg !default;
$nav-active-border-color: $nav-active-bg !default;
$nav-active-font-weight: $nav-font-weight !default;
$nav-active-icon: str-replace(
	url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='7.611' viewBox='0 0 14 7.611' fill='#{$nav-active-color}'><g transform='translate(-30)'><g transform='translate(30 0)'><path d='M8.949,88.97,5.381,85.4a.238.238,0,0,0-.336.336L8.207,88.9l-12.95.021a.238.238,0,0,0-.238.238.238.238,0,0,0,.238.238l12.95-.021L5.045,92.538a.238.238,0,1,0,.336.336l3.568-3.568A.238.238,0,0,0,8.949,88.97Z' transform='translate(4.981 -85.333)'></path></g></g></svg>"),
	"#",
	"%23"
) !default;
$nav-active-icon-height: 0.4757rem !default;
$nav-active-icon-width: 0.875rem !default;

// Navbar

$navbar-padding-y: $spacer / 2 !default;
$navbar-padding-x: $spacer !default;

$navbar-nav-link-padding-x: 0.5rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-dark-color: rgba($white, 0.5) !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: rgba($black, 0.5) !default;
$navbar-light-hover-color: rgba($black, 0.7) !default;
$navbar-light-active-color: rgba($black, 0.9) !default;
$navbar-light-disabled-color: rgba($black, 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg: $gray-200 !default;
$dropdown-divider-margin-y: $nav-divider-margin-y !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;

$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: 0.25rem !default;
$dropdown-item-padding-x: 1.5rem !default;

$dropdown-header-color: $gray-600 !default;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x !default;

// Pagination

$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $link-color !default;
$pagination-text-color: $body-color !default;
$pagination-text-transform: $btn-text-transform !default;
$pagination-bg: $body-bg !default;
$pagination-border-width: 0 !default;
$pagination-border-color: $gray-300 !default;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $pagination-bg !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;
$pagination-disabled-opacity: 0.5 !default;

// Jumbotron

$jumbotron-padding: 2rem !default;
$jumbotron-color: null !default;
$jumbotron-bg: $gray-200 !default;

// Cards

$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1.25rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black, 0.125) !default;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: $grid-gutter-width / 2 !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 400px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius-sm !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: $line-height-base !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;

// Popovers

$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, 0.2) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2) !default;

$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: 0.75rem !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;

// Toasts

$toast-max-width: 350px !default;
$toast-padding-x: 0.75rem !default;
$toast-padding-y: 0.25rem !default;
$toast-font-size: 0.875rem !default;
$toast-color: null !default;
$toast-background-color: rgba($white, 0.85) !default;
$toast-border-width: 1px !default;
$toast-border-color: rgba(0, 0, 0, 0.1) !default;
$toast-border-radius: 0.25rem !default;
$toast-box-shadow: 0 0.25rem 0.75rem rgba($black, 0.1) !default;

$toast-header-color: $gray-600 !default;
$toast-header-background-color: rgba($white, 0.85) !default;
$toast-header-border-color: rgba(0, 0, 0, 0.05) !default;

// Badges

$badge-font-size: 75% !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-y: 0.25em !default;
$badge-padding-x: 0.4em !default;
$badge-border-radius: $border-radius !default;

$badge-transition: $btn-transition !default;
$badge-focus-width: $input-btn-focus-width !default;

$badge-pill-padding-x: 0.6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 0 !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: 0 !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.8 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: 1rem !default;
$modal-header-padding-x: 1rem !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.75rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

// Progress bars

$progress-height: 1rem !default;
$progress-font-size: $font-size-base * 0.75 !default;
$progress-bg: $gray-200 !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1) !default;
$progress-bar-color: $white !default;
$progress-bar-bg: theme-color("primary") !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;

// List group

$list-group-color: null !default;
$list-group-bg: $white !default;
$list-group-border-color: rgba($black, 0.125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 1.25rem !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-200 !default;

// Image thumbnails

$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: $gray-300 !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075) !default;

// Figures

$figure-caption-font-size: 90% !default;
$figure-caption-color: $gray-600 !default;

// Breadcrumbs

$breadcrumb-font-size: $font-size-base !default;

$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding: 0.5rem !default;

$breadcrumb-margin-bottom: 0 !default;

$breadcrumb-bg: transparent !default;
$breadcrumb-color: inherit !default;
$breadcrumb-color-hover-opacity: 0.75 !default;
$breadcrumb-divider-color: $breadcrumb-color !default;
$breadcrumb-active-color: $breadcrumb-color !default;
$breadcrumb-active-opacity: 0.75 !default;
$breadcrumb-divider: quote("|") !default;

$breadcrumb-border-radius: $border-radius !default;
$breadcrumb-link-decoration: none !default;
$breadcrumb-text-transformation: uppercase !default;
$breadcrumb-font-weight: $font-weight-light !default;

// Carousel

$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;
$carousel-control-hover-opacity: 0.9 !default;
$carousel-control-transition: opacity 0.15s ease !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-active-bg: $white !default;
$carousel-indicator-transition: opacity 0.6s ease !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;

$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") !default;

$carousel-transition-duration: 0.6s !default;
$carousel-transition: transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Spinners

$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-border-width: 0.25em !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: 0.2em !default;

// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color: $black !default;
$close-text-shadow: 0 1px 0 $white !default;

// Code

$code-font-size: 87.5% !default;
$code-color: $pink !default;

$kbd-padding-y: 0.2rem !default;
$kbd-padding-x: 0.4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: $white !default;
$kbd-bg: $gray-900 !default;

$pre-color: $gray-900 !default;
$pre-scrollable-max-height: 340px !default;

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;
$user-selects: all, auto, none !default;

// Printing

$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, "lg") !default;

// Header variables
$header-padding-y: 2rem !default;
$header-padding-x: $header-padding-y !default;
$header-bg-color: transparent !default;
$header-color: color-yiq($header-bg-color, $cyan) !default;
$header-scrolling-bg-color: $cyan !default;
$header-border-color: rgba($header-color, 0.3) !default;
$header-border-width: $border-width !default;
$header-border-style: $border-style !default;
$header-max-height: calc(#{$header-padding-y * 2} + #{$font-size-sm * $line-height-base} + #{$header-border-width}) !default;
$header-responsive-breakpoint-down: md !default;

// Action buttons
$header-action-padding-y: $header-padding-y !default;
$header-action-padding-x: $header-padding-x !default;
$header-action-buttons-width: 1.5rem !default;
$header-action-buttons-height: #{$font-size-sm * $line-height-base} !default;

// Header search
$search-input-padding-x: 1.125rem !default;
$search-input-padding-y: 1.5rem !default;
$search-input-bg-color: darken(saturate($header-scrolling-bg-color, 9.65), 20.39) !default;
$search-input-border-color: $header-scrolling-bg-color !default;
$search-input-color: color-yiq($search-input-bg-color);
$search-input-border-width: #{$header-border-width * 2} !default;
$search-input-border-style: $header-border-style !default;

// Search Result
$search-result-padding-x: 0 !default;
$search-result-padding-y: $grid-gutter-width * 2 !default;
$search-result-padding: $search-result-padding-y $search-result-padding-x !default;
$search-result-margin-x: $search-result-padding-x !default;
$search-result-margin-y: $search-result-padding-y !default;
$search-result-margin: 0 $search-result-margin-x $search-result-margin-y !default;

// SideMenu
$side-menu-bg-color: $dark !default;
$side-menu-color: color-yiq($side-menu-bg-color, $dark) !default;
$side-menu-header-max-width: calc((#{$header-action-padding-x} * 2) + #{$header-action-buttons-width} + #{$header-border-width}) !default;
$side-menu-campuses-list-padding-x: 0 !default;
$side-menu-campuses-list-padding-y: 10rem !default;
$side-menu-campus-header-padding-x: 0 !default;
$side-menu-campus-header-padding-y: 2.1875rem !default;

// Campus card
$campus-padding-x: 1.875rem !default;
$campus-padding-y: $campus-padding-x !default;
$campus-header-padding-x: 1.25rem !default;
$campus-header-padding-y: $campus-header-padding-x !default;

// section
$section-padding-x: map-get($spacers, section) !default;
$section-padding-y: map-get($spacers, section) * 1.222222 !default;
$section-slider-buttons-width: 80px !default;
$section-slider-buttons-height: 74px !default;

// Application inner main header
$app-main-header-color: $white !default;
$app-main-header-padding-x: 0 !default;
$app-main-header-padding-y: 2.5rem !default;

// Application article aside section.
$app-article-aside-padding-x: $grid-gutter-width * 2 !default;
$app-article-aside-padding-y: $app-article-aside-padding-x !default;
$app-article-aside-padding: $app-article-aside-padding-y $app-article-aside-padding-x $app-article-aside-padding-y
	calc(#{$app-article-aside-padding-x} + #{$grid-gutter-width}) !default;

// Award card
$award-padding-x: 1.25rem !default;
$award-padding-y: calc(#{$award-padding-x * 2}) !default;
$award-padding: $award-padding-y $award-padding-x !default;
$award-font-weight: $font-weight-light !default;

// news card
$news-card-padding-x: 2.625rem;
$news-card-padding-y: 3.875rem;
$news-card-padding: $news-card-padding-y $news-card-padding-x;
$news-card-bg-color: $body-color;
$news-card-color: color-yiq($news-card-bg-color);

// news listing Result
$news-listing-margin-x: $grid-gutter-width !default;
$news-listing-margin-y: $grid-gutter-width * 4.6875 !default;
$news-listing-margin: 0 $news-listing-margin-x $news-listing-margin-y !default;

// process
$process-padding-x: $grid-gutter-width * 2 !default;
$process-padding-y: 0 !default;
$process-font-size: $font-size-sm !default;
$process-bg: $body-bg !default;
$process-color: color-yiq($process-bg) !default;
$process-title-color: color-yiq($process-bg, theme-color("primary-dark")) !default;
$process-title-font-size: $h2-font-size !default;

// Steps
$step-padding-x: 1.25rem !default;
$step-padding-y: 0.75rem !default;
$step-color: $primary !default;
$step-bg-color: color-yiq($step-color) !default;
$step-border-width: $border-width !default;
$step-border-color: $step-color !default;

$step-active-color: $white !default;
$step-active-bg-color: color-yiq($step-active-color, $primary) !default;
$step-active-border-width: $step-border-width !default;
$step-active-border-color: $step-active-bg-color !default;

$step-disabled-color: $white !default;
$step-disabled-bg-color: color-yiq($step-disabled-color, $gray-400) !default;
$step-disabled-border-width: $step-border-width !default;
$step-disabled-border-color: $step-active-bg-color !default;

$step-done-icon-bg: str-replace(
	url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 14'><path d='M66.308,28.1a.335.335,0,0,0-.474-.005l-13.124,12.8-4.45-5.085a.334.334,0,1,0-.5.44L52.441,41.6a.334.334,0,0,0,.237.114h.013a.335.335,0,0,0,.234-.095L66.3,28.575A.335.335,0,0,0,66.308,28.1Z' transform='translate(-47.113 -27.5)' fill='#{$step-disabled-color}' stroke='#{$step-disabled-color}' stroke-width='1'/></svg>"),
	"#",
	"%23"
) !default;
$step-done-icon-bg-height: 35px !default;
$step-done-icon-bg-width: 35px !default;

$step-hover-color: $step-active-color !default;
$step-hover-bg-color: $step-active-bg-color !default;
$step-hover-border-width: $step-active-border-width !default;
$step-hover-border-color: $step-active-border-color !default;

// Vacancies
$vacancy-spacer-y: 1.875rem !default;
$vacancy-spacer-x: 1.875rem !default;
$vacancy-border-width: 0 !default;
$vacancy-border-radius: 0 !default;
$vacancy-border-color: $border-color !default;
$vacancy-inner-border-radius: subtract($vacancy-border-radius, $vacancy-border-width) !default;
$vacancy-bg: $white !default;
$vacancy-color: color-yiq($white) !default;
$vacancy-height: null !default;
$vacancy-title-color: color-yiq($white, $headings-color) !default;

$vacancy-collapse-icon-bg-color: $primary-dark !default;
$vacancy-collapse-icon-color: color-yiq($vacancy-collapse-icon-bg-color) !default;
$vacancy-collapse-icon-bg-closed: str-replace(
	url("data:image/svg+xml,<svg width='1em' height='1em' viewBox='0 0 16 16' fill='#{$vacancy-collapse-icon-color}' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>"),
	"#",
	"%23"
) !default;
$vacancy-collapse-icon-bg-opened: str-replace(
	url("data:image/svg+xml,<svg width='1em' height='1em' viewBox='0 0 16 16' fill='#{$vacancy-collapse-icon-color}' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>"),
	"#",
	"%23"
) !default;
$vacancy-collapse-icon-bg-height: 2rem !default;
$vacancy-collapse-icon-bg-width: 2rem !default;

// Contact us map section
$contact-us-map-padding-x: 0;
$contact-us-map-padding-y: 3.25rem;
