.branch {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: $grid-gutter-width;
	color: $body-color;

	&__logo-container {
		height: 3.5rem;
		width: auto;
		max-width: 100%;
		margin-bottom: 2rem;
	}
	&__logo {
		height: 100%;
		width: 100%;
		object-fit: scale-down;
		object-position: center;
	}

	&__title {
		text-align: center;
		text-transform: uppercase;
		font-weight: $font-weight-light;
		// font-size: 1.25rem;
	}
}
