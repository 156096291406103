.app-footer {
	position: relative;

	p {
		margin-bottom: 0;
	}

	&__top,
	&__bottom {
		padding-top: 2.0625rem;
		padding-bottom: 2.0625rem;
		position: relative;
		&--content {
			text-align: center;
		}

		&:before {
			content: "";
			position: absolute;
			display: block;
			width: 100%;
			left: 50%;
			top: 0;
			height: $border-width;
			background-color: $border-color;
			transform: translateX(-50%);
		}
	}

	&__top {
		&--content {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__bottom {
		&--content {
			display: flex;
			align-items: center;
			justify-content: space-between;

			p {
				font-size: 0.75rem;
				font-weight: $font-weight-light;
				text-transform: uppercase;
				&,
				a {
					color: $gray-600;
				}
				a {
					margin-left: 3px;
					position: relative;
					overflow: hidden;
					text-decoration: none;
				}

				@include media-breakpoint-up(md) {
					display: inline-flex;
					align-items: center;

					a {
						display: inline-block;
					}
				}
			}
		}
	}
}
