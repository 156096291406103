* {
	&,
	&:before,
	&:after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;

		@if $enable-outline == false {
			outline: 0 !important;
		}
	}
}

html {
	font-size: $font-size-base;
}

body {
	font-family: $font-family-base #{"/*rtl:prepend:" $font-family-cairo ",*/"};
	&,
	#app {
		overflow-x: hidden;
	}

	&.app-preloader--shown,
	&.side-menu--is-open,
	&.nav-dropdown--is-open {
		overflow: hidden !important;
		overflow-y: hidden !important;
		scrollbar-width: none !important; /* Firefox 64 */
		position: relative; /* Safari */
		-ms-overflow-style: none !important; /* IE 11 */

		&::-webkit-scrollbar {
			width: 0 !important;
			background: transparent !important; /* Disable scrollbar Chrome/Safari/Webkit */
		}
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/** Gravity Forms */
.gform_wrapper form {
	margin-bottom: 0;
}

.gform_wrapper ul {
	@extend .list-unstyled;
}

.gform_wrapper li {
	@extend .form-group;
}

.gform_wrapper .gfield_required {
	padding-left: 1px;
	color: $danger;
}

.gform_wrapper input[type="email"],
.gform_wrapper input[type="date"],
.gform_wrapper input[type="datetime"],
.gform_wrapper input[type="datetime-local"],
.gform_wrapper input[type="month"],
.gform_wrapper input[type="number"],
.gform_wrapper input[type="password"],
.gform_wrapper input[type="search"],
.gform_wrapper input[type="tel"],
.gform_wrapper input[type="text"],
.gform_wrapper input[type="time"],
.gform_wrapper input[type="week"],
.gform_wrapper input[type="url"],
.gform_wrapper select,
.gform_wrapper textarea {
	@extend .form-control;
}
.gform_wrapper select {
	@extend .custom-select;
}

.gform_wrapper input[type="file"] {
	@extend .custom-file-input;
}

.gform_wrapper .gfield_checkbox li label {
	@extend .custom-control-label;
}
.gform_wrapper .gfield_checkbox li {
	@extend .custom-control;
	@extend .custom-checkbox;
}
.gform_wrapper input[type="checkbox"],
.gform_wrapper input[type="radio"] {
	position: absolute !important;
	left: 0 !important;
	z-index: -1 !important; // Put the input behind the label so it doesn't overlay text
	width: $custom-control-indicator-size !important;
	height: ($font-size-base * $line-height-base + $custom-control-indicator-size) / 2 !important;
	opacity: 0 !important;

	&:checked ~ label::before {
		color: $custom-control-indicator-checked-color !important;
		border-color: $custom-control-indicator-checked-border-color !important;
		@include gradient-bg($custom-control-indicator-checked-bg !important);
		@include box-shadow($custom-control-indicator-checked-box-shadow !important);
	}

	&:focus ~ label::before {
		// the mixin is not used here to make sure there is feedback
		@if $enable-shadows {
			box-shadow: $input-box-shadow, $input-focus-box-shadow !important;
		} @else {
			box-shadow: $custom-control-indicator-focus-box-shadow !important;
		}
	}

	&:focus:not(:checked) ~ label::before {
		border-color: $custom-control-indicator-focus-border-color !important;
	}

	&:not(:disabled):active ~ label::before {
		color: $custom-control-indicator-active-color !important;
		background-color: $custom-control-indicator-active-bg !important;
		border-color: $custom-control-indicator-active-border-color !important;
		@include box-shadow($custom-control-indicator-active-box-shadow !important);
	}

	// Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
	&[disabled],
	&:disabled {
		~ label {
			color: $custom-control-label-disabled-color !important;

			&::before {
				background-color: $custom-control-indicator-disabled-bg !important;
			}
		}
	}
}

.gform_wrapper .gfield_checkbox li label,
.gform_wrapper .gfield_radio li label {
	position: relative !important;
	margin-bottom: 0 !important;
	color: $custom-control-label-color !important;
	vertical-align: top !important;
	cursor: $custom-control-cursor !important;

	// Background-color and (when enabled) gradient
	&::before {
		position: absolute !important;
		top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2 !important;
		left: -($custom-control-gutter + $custom-control-indicator-size) !important;
		display: block !important;
		width: $custom-control-indicator-size !important;
		height: $custom-control-indicator-size !important;
		pointer-events: none !important;
		content: "" !important;
		background-color: $custom-control-indicator-bg !important;
		border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width !important;
		@include box-shadow($custom-control-indicator-box-shadow !important);
	}

	// Foreground (icon)
	&::after {
		position: absolute !important;
		top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2 !important;
		left: -($custom-control-gutter + $custom-control-indicator-size) !important;
		display: block !important;
		width: $custom-control-indicator-size !important;
		height: $custom-control-indicator-size !important;
		content: "" !important;
		background: no-repeat 50% / #{$custom-control-indicator-bg-size} !important;
	}
}
.gform_wrapper .gfield_checkbox li,
.gform_wrapper .gfield_radio li {
	@extend .custom-control;
	@extend .custom-radio;
	padding-left: $custom-control-gutter + $custom-control-indicator-size !important;
}

.gform_wrapper .gfield_checkbox li label {
	&::before {
		@include border-radius($custom-checkbox-indicator-border-radius !important);
	}
}
.gform_wrapper .gfield_radio li label {
	&::before {
		// stylelint-disable-next-line property-blacklist
		border-radius: $custom-radio-indicator-border-radius !important;
	}
}

.gform_wrapper input[type="checkbox"] {
	&:checked ~ label {
		&::after {
			background-image: escape-svg($custom-checkbox-indicator-icon-checked) !important;
		}
	}

	&:indeterminate ~ label {
		&::before {
			border-color: $custom-checkbox-indicator-indeterminate-border-color !important;
			@include gradient-bg($custom-checkbox-indicator-indeterminate-bg !important);
			@include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow !important);
		}
		&::after {
			background-image: escape-svg($custom-checkbox-indicator-icon-indeterminate) !important;
		}
	}

	&:disabled {
		&:checked ~ label::before {
			background-color: $custom-control-indicator-checked-disabled-bg !important;
		}
		&:indeterminate ~ label::before {
			background-color: $custom-control-indicator-checked-disabled-bg !important;
		}
	}
}
.gform_wrapper input[type="radio"] {
	&:checked ~ label {
		&::after {
			background-image: escape-svg($custom-radio-indicator-icon-checked) !important;
		}
	}

	&:disabled {
		&:checked ~ label::before {
			background-color: $custom-control-indicator-checked-disabled-bg !important;
		}
	}
}

.gform_wrapper .gsection {
	border-bottom: 0 !important;
}

.gform_wrapper .gsection_title {
	@extend .legend;
	padding: 0 !important;
	@include font-size($legend-font-size !important);
	font-weight: $legend-font-weight !important;
}

.gform_wrapper li.gfield.gfield_error {
	background-color: transparent;
	border-top: 0;
	border-bottom: 0;
}

.gform_wrapper .gfield_description {
	@extend .form-text;
	@extend .small;
}
.gform_wrapper .screen-reader-text {
	@extend .sr-only;
}

.gform_wrapper .ginput_container.ginput_container_list.ginput_list {
	@extend .px-3;
	@extend .py-4;
	@extend .bg-light;
}

.gform_wrapper .gfield_list_icon {
	a {
		@extend .btn;
		@extend .btn-link;
		@extend .btn-sm;
	}

	.add_list_item {
		@extend .text-primary;
	}

	.delete_list_item {
		@extend .text-danger;
	}
}

.gform_wrapper .gfield_list th,
.gform_wrapper .gfield_label {
	margin-bottom: $label-margin-bottom !important;
	font-weight: $label-font-weight !important;
	font-size: $label-font-size !important;
	color: $label-color !important;
	display: table-cell !important;
	background-color: transparent !important;
}

.gform_wrapper .gfield_list_cell {
	&:not(:last-child) {
		padding-right: $form-grid-gutter-width;
	}
}

.gform_wrapper .button {
	@extend .btn;
	@extend .btn-primary;
	@extend .btn-lg;
}

.gform_wrapper .gfield_error {
	input,
	select,
	textarea {
		@extend .is-invalid;
		border-color: $danger !important;
	}
}
.gform_wrapper li.gfield_error div.ginput_complex.ginput_container label,
.gform_wrapper li.gfield_error ul.gfield_checkbox,
.gform_wrapper li.gfield_error ul.gfield_radio {
	color: $danger !important;
}

.gform_validation_error .validation_error {
	@extend .alert;
	@extend .alert-danger;
	border: 0 !important;
	font-weight: $font-weight-base !important;
	text-align: initial !important;
	padding: $alert-padding-y $alert-padding-x !important;
}

.validation_message {
	@extend .invalid-feedback;
	color: $danger !important;
}

.gf_step {
	height: auto !important;
	opacity: 1 !important;
	@extend .step;
	&_active {
		@extend .step--active;
	}
	&_number {
		@extend .step__number;
	}
	&_label {
		@extend .step__label;
	}

	&_completed {
		@extend .step--done;
	}

	&:not(.gf_step_active):not(.gf_step_pending) {
		@extend .step--done;
	}
}

.gform_wrapper .gf_page_steps,
.gform_wrapper .gform_page_footer {
	border: 0 !important;
}

// Wordpress
.app-header__nav--is-open .menu-top-menu-container {
	width: 100%;
}
