@if $use-rotateOutDownRight == true {

	@-webkit-keyframes rotateOutDownRight {
		0% {
			-webkit-transform-origin: right bottom;
			-webkit-transform: rotate(0);
			opacity: 1;
		}

		100% {
			-webkit-transform-origin: right bottom;
			-webkit-transform: rotate(-$rotate-degrees);
			opacity: 0;
		}
	}

	@keyframes rotateOutDownRight {
		0% {
			transform-origin: right bottom;
			transform: rotate(0);
			opacity: 1;
		}

		100% {
			transform-origin: right bottom;
			transform: rotate(-$rotate-degrees);
			opacity: 0;
		}
	}

	.rotateOutDownRight {
		@include animate-prefixer(animation-name, rotateOutDownRight);
	}
}
