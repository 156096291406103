/*
** FONTS
** ==============================================
*/
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&family=Cinzel:wght@400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
	font-family: "Rage";
	src: url("./fonts/RageItalic.eot");
	src: url("./fonts/RageItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/RageItalic.woff2") format("woff2"),
		url("./fonts/RageItalic.woff") format("woff"), url("./fonts/RageItalic.ttf") format("truetype"),
		url("./fonts/RageItalic.svg#RageItalic") format("svg");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

/*
** LIBRARIES
** ==============================================
*/
@import "libs/bootstrap/bootstrap";
@import "libs/fontawesome/fontawesome";
@import "libs/swiper/swiper";
@import "libs/animate-css/animate";
@import "libs/aos/aos";
@import "libs/fullcalender/fullcalender";

/*
** HELPERS
** ==============================================
*/
@import "helpers/debug";
@import "helpers/mixins";
@import "helpers/functions";
@import "helpers/helper_classes";

/*
** COMPONENTS
** ==============================================
*/
@import "components/social";
@import "components/logos";
@import "components/branch";
@import "components/nav";
@import "components/campus";
@import "components/search_result";
@import "components/award";
@import "components/news_card";
@import "components/process";
@import "components/step";
@import "components/vacancy";
@import "components/resources_list";
@import "components/bulletin_card";
@import "components/activity_card";
@import "components/logo";

/*
** SECTIONS
** ==============================================
*/
@import "sections/sections";
@import "sections/app_preloader";
@import "sections/app_header";
@import "sections/app_main";
@import "sections/app_main_header";
@import "sections/app_article";
@import "sections/app_article_content";
@import "sections/app_article_aside";
@import "sections/app_article_share";
@import "sections/app_article_header";
@import "sections/app_article_header_section";
@import "sections/app_footer";
@import "sections/app_hero";
@import "sections/campuses_list";
@import "sections/our_campuses";
@import "sections/news_showcase";
@import "sections/contact_us";
@import "sections/search_results";
@import "sections/news_listing";
@import "sections/contact_us_map";
@import "sections/professional_development";
@import "sections/school_system";
@import "sections/activities_events_gallery";
@import "sections/bulletin_showcase_section";

/*
** PAGES
** ==============================================
*/
@import "pages/index";
@import "pages/about";
@import "pages/search_results";
@import "pages/error";
@import "pages/news_listing";
@import "pages/news_single";
@import "pages/volunteering";
@import "pages/admission";
@import "pages/careers";
@import "pages/school_index";
@import "pages/school_about";
@import "pages/school_activities_single";

/*
** OVERWRITING STYLE
** ==============================================
*/
@import "custom_style";
