.our-campuses-section {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;

	.swiper-container {
		height: 100%;
		&#our-campuses-section-gallery-lg-slider {
			.section__media-container {
				.section__media-image {
					min-height: 31.25rem;
				}
			}
		}
	}
}
